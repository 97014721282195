.advert
{
	margin: 0 auto;
	position: relative;
	text-align: center;

	&--shallow
	{
		margin-bottom: 1rem;
	}
}

.ad-container 
{
	margin-bottom: 1rem;
}

.top-ad
{
	margin: 0 auto;
	max-width: 100%;
	overflow: hidden;

	&__container
	{
		text-align: center;

		&__image
		{
			@include vertical-align;
			margin: 0 auto;
		}
	}

	&__sticky-wrapper
	{
		height: rem-calc($mobile-top-advert-container-height-value + $mobile-top-advert-container-padding-value);

		&__inner
		{
			background-color: $white;
			height: 100%;
			max-height: rem-calc($mobile-top-advert-container-height-value + (2 * $mobile-top-advert-container-padding-value) + $mobile-top-advert-timer-height-value);
			overflow: hidden;
			width: 100%;

			.top-ad__container
			{
				@include vertical-align;
			}

			&--stuck
			{
				background: transparent;
				height: auto;
				margin: 0 auto;
				position: fixed;
				top: 0;
				transform: translateY(0);
				transition: transform 0.7s;
				z-index: z-index(10);

				.top-ad__container
				{
					top: 0;
					transform: none;
				}
			}
		}
	}
}

.inline-leaderboard-ad
{
	@include vertical-align;
	
	&--stuck
	{
		z-index: z-index(4);

		> :first-child
		{
			position: fixed;
			top: 0;
		}
	}
}

.inline-footer-ad
{
	margin: 1rem auto;
}

.mpu-ad
{
	max-width: rem-calc(300);	// small size will need to be able to shrink the banner to fit in screen
	min-height: rem-calc(250);

	&--full-width
	{
		max-width: initial;
		min-height: initial;
	}

	&:before
	{
		color: $charcoal;
		content: '\25BC  Scroll for more content below this advert  \25BC';
		display: block;
		font-size: 0.8rem;
		margin: 1rem 0 0.25rem;
		text-align: center;
	}

	&:empty
	{
		background-color: $white-smoke;
	}

	&--no-scroll-message
	{
		&:before
		{
			content: '';
		}
	}

	&--stuck
	{
		z-index: z-index(4);

		> :first-child
		{
			position: fixed;
			top: 0;
		}

		&.mpu-ad--double
		{
			> :first-child
			{
				transform: initial;
			}
		}
	}
}

.native-ad
{
	margin: 0 auto;
	min-height: rem-calc(1); // lazy loading ad in chrome doesnt work if it doesnt have a height
}

.overlay-ad
{
	height: 0;
	margin: 0;
}

.bottom-ad
{
	margin-top: 2rem;

	.advert
	{
		margin-bottom: 1rem;
	}
}

.vertical-centered-ad
{
	@include vertical-align;
}

.zero-height-ad
{
	height: 0;
}

@media #{$large-up}
{
	.ad-container 
	{
		clear: both;

		&--mpu
		{
			float: right;
			margin-left: 0.5rem;
		}

		&--full-width
		{
			margin-left: auto;
			margin-right: auto;
		}
	}

	// this is the main container before an ad loads, setting height stops
	// page from shifting vertically
	.inline-leaderboard-ad-wrapper
	{
		height: rem-calc(250);
		margin: 1rem auto;
	}

	.top-ad
	{
		&__sticky-wrapper
		{
			&--leaderboard-inline
			{
				display: none;
			}
		}
	}

	.mpu-ad
	{
		min-width: rem-calc(300);

		&:before
		{
			content: none;
		}

		&--stuck
		{
			z-index: z-index(4);

			> :first-child
			{
				left: 0;
				margin: 0;
				transform: none;
			}

			&.mpu-ad--double
			{
				> :first-child
				{
					transform: initial;
				}
			}
		}

		&--full-width
		{
			&:before
			{
				content: '\25BC  Scroll for more content below this advert  \25BC';
			}
		}
	}

	.moveable-mpu-container
	{
		&--full-width
		{
			display: flex;
			flex-direction: column;
		}

		&__content
		{
			order: 0;
		}

		&__mpu
		{
			order: 1;
		}
	}

	.resizeable-mpu
	{
		&--right
		{
			clear: both;
			float: right;
			margin-left: 0.5rem;
		}

		&--left
		{
			float: left;
			margin-right: 0.5rem;
		}
	}
}
