.site-breadcrumbs
{
	margin-top: 0.25rem;

	.site-breadcrumbs
	{
		&__trail
		{
			@extend %inline-list;
			font-family: $breadcrumb-font;
			font-size: 0.9rem;

			&__item
			{
				margin-right: 0.6rem;

				&:after
				{
					color: $aluminum;
					content: '>';
				}

				&:last-child
				{
					margin-right: 0;

					&:after
					{
						content: none;
					}
				}
			}

			&__link,
			&__text
			{
				margin-right: 0.4rem;
			}
		}
	}
}
