.pipe-list
{
	@extend %collapsed-list;
	@include clearfix;
	font-family: $link-list-font;
	font-size: 0.95rem;
	line-height: 1;
	margin: 0.5rem 0 1rem 0;

	&__label
	{
		display: block;
		margin-bottom: 0.5rem;
	}

	&__item
	{
		display: block;
		margin: 0 0 0.5rem 1rem;
	}

	&--force-horizontal
	{
		.pipe-list__item
		{
			border-right: rem-calc(1) solid $iron;
			float: left;
			padding-right: 1rem;

			&:last-child
			{
				border-right: 0;
				margin-right: 0;
			}
		}
	}
}

@media #{$medium-up}
{
	.pipe-list
	{
		&__label
		{
			float: left;
		}

		&__item
		{
			border-right: rem-calc(1) solid $iron;
			float: left;
			padding-right: 1rem;

			&:last-child
			{
				border-right: 0;
				margin-right: 0;
			}
		}
	}
}
