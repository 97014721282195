// This is used on the homepage car finance calculator and also financewidget even tho that looks completely different
.widget
{
	@include clearfix;
	background-color: $ghost;
	border: rem-calc(1) solid $dark-blue;
	border-radius: rem-calc(5);
	margin-bottom: 2rem;

	.widget
	{
		&__heading
		{
			background-color: $dark-blue;
			border-radius: rem-calc(5 5 0 0);
			color: $white;
			font-size: 1.4rem;
			margin: 0 rem-calc(-1);
			padding: rem-calc(10) rem-calc(12);		}

		&__content
		{
			padding: 0.5rem 1.4rem;

			&__input
			{
				&__heading
				{
					color: $monsoon;
					line-height: rem-calc(25);
					margin-bottom: 0.25rem;
				}
			}

			&__button
			{
				margin: 0;
				padding: 0.5rem;
				white-space: normal;
				word-wrap: break-word;
			}
		}
	}

	&--blue
	{
		border-color: $blue;

		.widget
		{
			&__heading
			{
				background-color: $blue;
			}
		}
	}
}
