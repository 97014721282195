﻿.tabs
{
	@extend %inline-list;
	margin-bottom: 0.5rem;

	&__item
	{
		&.active
		{
			.tabs__link
			{
				background: $cyan;
				border-color: $cyan;
				color: $white;
				cursor: default;

				&:hover
				{
					background: $cyan;
				}
			}
		}
	}

	&__link
	{
		background-image: linear-gradient(lighten($background-blue, 5%), lighten($background-blue, 2%));
		border: rem-calc(1) solid $gainsboro;
		color: $jet;
		display: block;
		font-family: $tab-font;
		font-size: 1rem;
		padding: 0.2rem 0.7rem 0.4rem 0.7rem;
		position: relative;
		text-align: center;
		white-space: nowrap;

		&:hover
		{
			background: $background-blue;
			color: $jet;
		}
	}
}

.tabs-content
{
	margin-bottom: 1.5rem;
}

.tab-content
{
	display: none;
	padding: 0.75rem 0;

	&.active
	{
		display: block;
	}
}

@media #{$small-only}
{
	.tabs
	{
		&__item
		{
			margin: 0 0 0.2rem 0;
			width: 100%;
		}
	}
}

@media #{$small-only}, #{$medium-only}
{
	.tabs
	{
		&__item
		{
			box-sizing: border-box;
			margin: 0 0 0.2rem 0;
			width: 50%;

			&:nth-child(odd)
			{
				padding-right: 0.1rem;
			}

			&:nth-child(even)
			{
				padding-left: 0.1rem;
			}
		}
	}
}

@media #{$large-up}
{
	.tabs
	{
		border-bottom: rem-calc(2) solid $cyan;

		&__item
		{
			float: left;
			margin: 0 0.25rem 0 0;

			&.active
			{
				.tab__link
				{
					&:after
					{
						display: none;
					}
				}
			}
		}

		&__link
		{
			border-bottom: 0;
		}
	}
}
