.toggle-switch
{
	&__switches
	{
		background-color: $white;
		border: rem-calc(1) solid $iron;
		border-radius: 1rem;
		left: -0.1rem;
		line-height: 1;
		margin-bottom: 0.3rem;
		max-width: rem-calc(250);
		padding: rem-calc(2);
		position: relative;
	}

	&__switch
	{
		border-radius: 1rem;
		color: $primary-color;
		cursor: pointer;
		display: inline-block;
		font-family: $search-filters-font;
		font-size: 0.8rem;
		line-height: 1.8;
		text-align: center;
		width: 50%;

		&:hover
		{
			color: $red;
		}

		&--active
		{
			background-color: $red;
			color: $white;

			&:hover
			{
				color: $white;
			}
		}

		&--large
		{
			font-size: 0.9rem;
		}
	}
}
