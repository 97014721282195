.similar-cars-for-sale-carousel-container
{	
	.similar-cars-for-sale-carousel-container
	{
		&__inner
		{
			margin: 0 3rem;
			overflow: hidden;
		}
	}
}

.similar-cars-for-sale-carousel
{
	height: rem-calc(250);

	.similar-cars-for-sale-carousel
	{
		&__item
		{
			border: rem-calc(1) solid $gainsboro;
			height: 10rem;
			margin: 1rem 0.5rem;
			outline: none;
			visibility: hidden;

			.row
			{
				margin: 1rem 0;
			}

			&__thumbnail
			{
				display: block;
				padding: 0 0.5rem;
			}
			
			&__heading
			{
				display: block;
				font-family: $body-style-font;
				font-size: 0.9rem;
				letter-spacing: 0.05rem;
				margin: 0.1rem 0 0.3rem 0;
			}

			&__derivative
			{
				display: block;
			}

			&__mileage
			{
				display: block;
				font-size: 0.75rem;
				vertical-align: top;
			}

			&__price
			{
				color: $red;
				display: block;
				font-family: $body-style-font;
				width: 100%;
			}

			&__link
			{
				@include background-icon-16x16-chevron;
				float: right;
				font-size: 0.9rem;
				line-height: rem-calc(16);
				margin: 1rem 0 0 1.5rem;
				padding-left: 1.5rem;
			}

			.column
			{
				padding: 0 rem-calc(3);
			}
		}
	}
}

.similar-cars-for-sale
{
	border: rem-calc(1) solid $gainsboro;
	margin: 0.875rem 0;
	max-height: rem-calc(350);
	overflow: hidden;
	padding: 0.5rem 0.5rem 0 0.5rem;
	position: relative;

	&.carousel-loaded
	{
		max-height: none;

		.similar-cars-for-sale-carousel__item
		{
			visibility: visible;
		}
	}

	.similar-cars-for-sale
	{
		&__heading
		{
			display: block;
			font-family: $heading-font;
			font-size: 1.4rem;
			font-weight: 700;
			line-height: 2rem;
			margin: 0.4rem 0 0.2rem 0;
		}
	}

	.rsArrowIcn
	{
		background-color: $blue;
		border-radius: 1rem;
	}

	.rsOverflow
	{
		max-height: rem-calc(250);
	}
}
