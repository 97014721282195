﻿.vrm-lookup
{
	&__input[type="text"],
	&__input--small[type="text"]
	{
		background: $yellow url('../images/widgets/eu-badge.png') no-repeat !important;
		border-color: $aluminum;
		border-radius: rem-calc(5);
		color: $black;
		font-family: $vrm-font;
		font-size: 2.8rem; 
		font-weight: 700;
		height: 3.5rem !important;
		letter-spacing: 0.1rem;
		line-height: 3.5rem !important;
		margin-bottom: 0.5rem;
		padding: 0 0.5rem 0 3rem;
		text-align: center;
		text-transform: uppercase;

		&::placeholder
		{
			color: $monsoon;
			font-family: $vrm-font;
		}
	}

	&__input--small[type="text"]
	{
		background-size: auto 2.5rem;
		font-size: 1.5rem;
		height: 2.5rem;
		line-height: 1;
		padding-left: 2rem;
		padding-top: 0.2rem;


		&::placeholder
		{
			font-size: 1rem;
		}

		&:focus
		{
			background-size: auto 2.5rem;
		}
	}
}
