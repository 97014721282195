﻿.social-nav
{
	$social-link-size: 40;
	
	@extend %inline-list;
	margin-bottom: 1rem;

	.social-nav
	{
		&__item
		{
			margin-right: 0.5rem;
		}

		&__link
		{
			background-color: $oil;
			color: $ghost;
			display: block;
			font-size: 1.8rem;
			height: rem-calc($social-link-size);
			line-height: rem-calc($social-link-size);
			text-align: center;
			width: rem-calc($social-link-size);

			&--facebook
			{
				background-color: $facebook-colour;
			}

			&--twitter
			{
				background-color: $twitter-colour;
			}

			&--you-tube
			{
				background-color: $youtube-colour;
			}
		}
	}
}
