﻿.site-sidebar-left
{
	background-color: $lightened-background-blue;
	box-shadow: -0.3125rem 0 0.625rem 0 rgba(0, 0, 0, 0.08) inset;

	.site-sidebar-left
	{
		&__sticky-wrapper
		{
			&__inner
			{
				box-sizing: border-box;
				padding: 0.5rem 1rem 1rem 0.75rem;
			}
		}

		&__search-filters__container
		{
			$totalHeightOfHeaderFooterElements: 300; // mobile footer, address bar, header.
			height: calc(100vh - #{$totalHeightOfHeaderFooterElements}px);
			overflow-y: scroll;
			padding-left: 0.1rem;
		}
	}

	@media #{$large-up}
	{
		.site-sidebar-left__search-filters__container
		{
			height: inherit;
			overflow-y: inherit;
		}
	}

	@media #{$xlarge-up}
	{
		background-image: linear-gradient(90deg, darken($lightened-background-blue, 2%), $lightened-background-blue);
		position: relative;

		.site-sidebar-left
		{
			&__sticky-wrapper
			{
				&__inner
				{
					width: $desktop-left-sidebar-width;
				}
			}
		}
	}
}
