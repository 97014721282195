@mixin bodystyle-sprite($background-x, $background-y, $height, $width)
{	
	background-image: url('../Images/Bodystyles/bodystyles_1741772980279.png');
	background-position: rem-calc($background-x $background-y);
	background-repeat: no-repeat;
	background-size: rem-calc(80 3360);
	height: rem-calc($height);
	width: rem-calc($width);
}


@mixin bodystyle-4x4-black
{
	@include bodystyle-sprite(0, 0, 40, 80);
}

@mixin bodystyle-4x4-grey
{
	@include bodystyle-sprite(0, -40, 40, 80);
}

@mixin bodystyle-4x4-light-grey
{
	@include bodystyle-sprite(0, -80, 40, 80);
}

@mixin bodystyle-4x4-white
{
	@include bodystyle-sprite(0, -120, 40, 80);
}

@mixin bodystyle-car-van-black
{
	@include bodystyle-sprite(0, -160, 40, 80);
}

@mixin bodystyle-car-van-grey
{
	@include bodystyle-sprite(0, -200, 40, 80);
}

@mixin bodystyle-car-van-light-grey
{
	@include bodystyle-sprite(0, -240, 40, 80);
}

@mixin bodystyle-car-van-white
{
	@include bodystyle-sprite(0, -280, 40, 80);
}

@mixin bodystyle-commercial-4x4-black
{
	@include bodystyle-sprite(0, -320, 40, 80);
}

@mixin bodystyle-commercial-4x4-grey
{
	@include bodystyle-sprite(0, -360, 40, 80);
}

@mixin bodystyle-commercial-4x4-light-grey
{
	@include bodystyle-sprite(0, -400, 40, 80);
}

@mixin bodystyle-commercial-4x4-white
{
	@include bodystyle-sprite(0, -440, 40, 80);
}

@mixin bodystyle-conversion-black
{
	@include bodystyle-sprite(0, -480, 40, 80);
}

@mixin bodystyle-conversion-grey
{
	@include bodystyle-sprite(0, -520, 40, 80);
}

@mixin bodystyle-conversion-light-grey
{
	@include bodystyle-sprite(0, -560, 40, 80);
}

@mixin bodystyle-conversion-white
{
	@include bodystyle-sprite(0, -600, 40, 80);
}

@mixin bodystyle-convertible-roadster-black
{
	@include bodystyle-sprite(0, -640, 40, 80);
}

@mixin bodystyle-convertible-roadster-grey
{
	@include bodystyle-sprite(0, -680, 40, 80);
}

@mixin bodystyle-convertible-roadster-light-grey
{
	@include bodystyle-sprite(0, -720, 40, 80);
}

@mixin bodystyle-convertible-roadster-white
{
	@include bodystyle-sprite(0, -760, 40, 80);
}

@mixin bodystyle-coupe-black
{
	@include bodystyle-sprite(0, -800, 40, 80);
}

@mixin bodystyle-coupe-grey
{
	@include bodystyle-sprite(0, -840, 40, 80);
}

@mixin bodystyle-coupe-light-grey
{
	@include bodystyle-sprite(0, -880, 40, 80);
}

@mixin bodystyle-coupe-white
{
	@include bodystyle-sprite(0, -920, 40, 80);
}

@mixin bodystyle-electric-hybrid-black
{
	@include bodystyle-sprite(0, -960, 40, 80);
}

@mixin bodystyle-electric-hybrid-grey
{
	@include bodystyle-sprite(0, -1000, 40, 80);
}

@mixin bodystyle-electric-hybrid-light-grey
{
	@include bodystyle-sprite(0, -1040, 40, 80);
}

@mixin bodystyle-electric-hybrid-white
{
	@include bodystyle-sprite(0, -1080, 40, 80);
}

@mixin bodystyle-estate-black
{
	@include bodystyle-sprite(0, -1120, 40, 80);
}

@mixin bodystyle-estate-grey
{
	@include bodystyle-sprite(0, -1160, 40, 80);
}

@mixin bodystyle-estate-light-grey
{
	@include bodystyle-sprite(0, -1200, 40, 80);
}

@mixin bodystyle-estate-white
{
	@include bodystyle-sprite(0, -1240, 40, 80);
}

@mixin bodystyle-family-black
{
	@include bodystyle-sprite(0, -1280, 40, 80);
}

@mixin bodystyle-family-grey
{
	@include bodystyle-sprite(0, -1320, 40, 80);
}

@mixin bodystyle-family-light-grey
{
	@include bodystyle-sprite(0, -1360, 40, 80);
}

@mixin bodystyle-family-white
{
	@include bodystyle-sprite(0, -1400, 40, 80);
}

@mixin bodystyle-fast-sports-black
{
	@include bodystyle-sprite(0, -1440, 40, 80);
}

@mixin bodystyle-fast-sports-grey
{
	@include bodystyle-sprite(0, -1480, 40, 80);
}

@mixin bodystyle-fast-sports-light-grey
{
	@include bodystyle-sprite(0, -1520, 40, 80);
}

@mixin bodystyle-fast-sports-white
{
	@include bodystyle-sprite(0, -1560, 40, 80);
}

@mixin bodystyle-hatchback-black
{
	@include bodystyle-sprite(0, -1600, 40, 80);
}

@mixin bodystyle-hatchback-grey
{
	@include bodystyle-sprite(0, -1640, 40, 80);
}

@mixin bodystyle-hatchback-light-grey
{
	@include bodystyle-sprite(0, -1680, 40, 80);
}

@mixin bodystyle-hatchback-white
{
	@include bodystyle-sprite(0, -1720, 40, 80);
}

@mixin bodystyle-large-van-black
{
	@include bodystyle-sprite(0, -1760, 40, 80);
}

@mixin bodystyle-large-van-grey
{
	@include bodystyle-sprite(0, -1800, 40, 80);
}

@mixin bodystyle-large-van-light-grey
{
	@include bodystyle-sprite(0, -1840, 40, 80);
}

@mixin bodystyle-large-van-white
{
	@include bodystyle-sprite(0, -1880, 40, 80);
}

@mixin bodystyle-light-truck-black
{
	@include bodystyle-sprite(0, -1920, 40, 80);
}

@mixin bodystyle-light-truck-grey
{
	@include bodystyle-sprite(0, -1960, 40, 80);
}

@mixin bodystyle-light-truck-light-grey
{
	@include bodystyle-sprite(0, -2000, 40, 80);
}

@mixin bodystyle-light-truck-white
{
	@include bodystyle-sprite(0, -2040, 40, 80);
}

@mixin bodystyle-medium-van-black
{
	@include bodystyle-sprite(0, -2080, 40, 80);
}

@mixin bodystyle-medium-van-grey
{
	@include bodystyle-sprite(0, -2120, 40, 80);
}

@mixin bodystyle-medium-van-light-grey
{
	@include bodystyle-sprite(0, -2160, 40, 80);
}

@mixin bodystyle-medium-van-white
{
	@include bodystyle-sprite(0, -2200, 40, 80);
}

@mixin bodystyle-minibus-black
{
	@include bodystyle-sprite(0, -2240, 40, 80);
}

@mixin bodystyle-minibus-grey
{
	@include bodystyle-sprite(0, -2280, 40, 80);
}

@mixin bodystyle-minibus-light-grey
{
	@include bodystyle-sprite(0, -2320, 40, 80);
}

@mixin bodystyle-minibus-white
{
	@include bodystyle-sprite(0, -2360, 40, 80);
}

@mixin bodystyle-mpv-people-carrier-black
{
	@include bodystyle-sprite(0, -2400, 40, 80);
}

@mixin bodystyle-mpv-people-carrier-grey
{
	@include bodystyle-sprite(0, -2440, 40, 80);
}

@mixin bodystyle-mpv-people-carrier-light-grey
{
	@include bodystyle-sprite(0, -2480, 40, 80);
}

@mixin bodystyle-mpv-people-carrier-white
{
	@include bodystyle-sprite(0, -2520, 40, 80);
}

@mixin bodystyle-pickup-black
{
	@include bodystyle-sprite(0, -2560, 40, 80);
}

@mixin bodystyle-pickup-grey
{
	@include bodystyle-sprite(0, -2600, 40, 80);
}

@mixin bodystyle-pickup-light-grey
{
	@include bodystyle-sprite(0, -2640, 40, 80);
}

@mixin bodystyle-pickup-white
{
	@include bodystyle-sprite(0, -2680, 40, 80);
}

@mixin bodystyle-saloon-black
{
	@include bodystyle-sprite(0, -2720, 40, 80);
}

@mixin bodystyle-saloon-grey
{
	@include bodystyle-sprite(0, -2760, 40, 80);
}

@mixin bodystyle-saloon-light-grey
{
	@include bodystyle-sprite(0, -2800, 40, 80);
}

@mixin bodystyle-saloon-white
{
	@include bodystyle-sprite(0, -2840, 40, 80);
}

@mixin bodystyle-small-city-black
{
	@include bodystyle-sprite(0, -2880, 40, 80);
}

@mixin bodystyle-small-city-grey
{
	@include bodystyle-sprite(0, -2920, 40, 80);
}

@mixin bodystyle-small-city-light-grey
{
	@include bodystyle-sprite(0, -2960, 40, 80);
}

@mixin bodystyle-small-city-white
{
	@include bodystyle-sprite(0, -3000, 40, 80);
}

@mixin bodystyle-small-van-black
{
	@include bodystyle-sprite(0, -3040, 40, 80);
}

@mixin bodystyle-small-van-grey
{
	@include bodystyle-sprite(0, -3080, 40, 80);
}

@mixin bodystyle-small-van-light-grey
{
	@include bodystyle-sprite(0, -3120, 40, 80);
}

@mixin bodystyle-small-van-white
{
	@include bodystyle-sprite(0, -3160, 40, 80);
}

@mixin bodystyle-suv-black
{
	@include bodystyle-sprite(0, -3200, 40, 80);
}

@mixin bodystyle-suv-grey
{
	@include bodystyle-sprite(0, -3240, 40, 80);
}

@mixin bodystyle-suv-light-grey
{
	@include bodystyle-sprite(0, -3280, 40, 80);
}

@mixin bodystyle-suv-white
{
	@include bodystyle-sprite(0, -3320, 40, 80);
}
