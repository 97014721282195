﻿.feature-box
{
	@include clearfix;
	background-image: linear-gradient(lighten($background-blue, 5%), lighten($background-blue, 2%));
	border: rem-calc(1) solid $gainsboro;
	border-radius: rem-calc(0 0 5 5);
	border-top: rem-calc(3) solid $blue;
	box-shadow: inset rem-calc(0 -2 2 -2) $oil;
	margin-bottom: 2rem;
	padding: 0.5rem 1rem;

	&--green
	{
		border-top-color: $mint-green;
	}

	&--red
	{
		border-top-color: $red;
	}

	&--yellow
	{
		border-top-color: $yellow;
	}

	&--orange
	{
		border-top-color: $orange;
	}

	&__heading
	{
		margin-top: 0;

		&__link
		{
			color: $jet;
			transition: color 200ms;

			&:hover
			{
				color: $primary-color;
			}
		}
	}

	&__image
	{
		border: rem-calc(1) solid $gainsboro;
		display: block;
		margin: 0 auto 1.5rem auto;
	}

	&__text
	{
		font-size: 0.9rem;
		line-height: 1.2;
		margin-bottom: 1rem;
	}
}
