.button
{
	background-color: $blue;
	border: rem-calc(2) solid $blue;
	border-radius: 0.25rem;
	color: $white;
	font-family: $button-font;
	font-size: 1.1rem;
	margin-bottom: 0.5rem;
	outline: none;
	padding: 0.5rem 1rem;
	position: relative;
	text-align: center;
	transition: all 200ms;
	width: 100%;

	&:focus:not([disabled]),
	&:hover:not([disabled])
	{
		background-color: $button-bg-hover;
		border: rem-calc(2) solid $button-bg-hover;
		color: $white;
	}

	&--light
	{
		border: rem-calc(1) solid $blue;
	}

	&--left-pad
	{
		padding-left: 2.5rem;
	}

	&--right-pad
	{
		padding-right: 2.5rem;
	}

	&--left-pad-small
	{
		padding-left: 1rem;
		padding-right: 0;
	}

	&--right-pad-small
	{
		padding-left: 0;
		padding-right: 1rem;
	}

	&--centered
	{
		display: block;
		margin: auto;

		@media #{$large-up}
		{
			width: $desktop-button-width;
		}
	}

	.button
	{
		&__arrow
		{
			border-bottom: 0.25rem solid $white;
			border-right: 0.25rem solid $white;
			height: 0;
			padding: 0.5rem;
			position: absolute;
			right: 1rem;
			top: 50%;
			transform: translateY(-50%) rotate(-45deg);
			width: 0;

			&--left
			{
				left: 1rem;
				right: initial;
				transform: translateY(-50%) rotate(135deg);
			}

			&--small
			{
				border-width: 0.2rem;
				padding: 0.25rem;
				right: 0.5rem;

				&.button__arrow--left
				{
					left: 0.5rem;
					right: initial;
				}
			}
		}
	}

	&--hollow
	{
		background-color: transparent;
		color: $blue;

		&:focus:not([disabled]),
		&:hover:not([disabled])
		{
			background-color: transparent;
			border: rem-calc(2) solid $button-bg-hover;
			color: $button-bg-hover;
		}

		.button
		{
			&__arrow
			{
				border-bottom-color: $blue;
				border-right-color: $blue;
			}
		}
	}

	&--hollow-light
	{
		&:focus:not([disabled]),
		&:hover:not([disabled])
		{
			border: rem-calc(1) solid $button-bg-hover;
		}
	}

	&--hollow-white
	{
		background-color: transparent;
		border: rem-calc(2) solid $white;
		color: $white;

		&:focus:not([disabled]),
		&:hover:not([disabled])
		{
			background-color: transparent;
			border: rem-calc(2) solid $white;
			color: $white;
		}

		.button
		{
			&__arrow
			{
				border-bottom-color: $white;
				border-right-color: $white;
			}
		}
	}

	&--grey
	{
		background-color: $gainsboro;
		border: rem-calc(1) solid $black;
		color: $black;

		&:focus:not([disabled]),
		&:hover:not([disabled])
		{
			background-color: $white;
			border: rem-calc(1) solid $black;
			color: $black;
		}

		.button
		{
			&__arrow
			{
				border-bottom-color: $black;
				border-right-color: $black;
			}
		}
	}
}

// Override Foundation built in style
input
{
	&[type="submit"]
	{
		&.button
		{
			border-radius: 0.25rem;
		}
	}
}
