.action-box
{
	margin-bottom: 2rem;

	&__panel
	{
		&__heading
		{
			&__link
			{
				display: block;
			}
		}

		&__content
		{
			display: none;
		}

		&--active
		{
			.action-box__panel
			{
				&__content
				{
					display: block;
				}

				&__expander
				{
					display: none;
				}
			}
		}
	}

	@media #{$small-only}, #{$medium-only}
	{
		&__panel
		{
			margin-bottom: 1.5rem;
		}
	}

	@media #{$large-up}
	{
		&__panel
		{
			&__content
			{
				display: none !important;
			}

			&__expander
			{
				display: none;
			}

			&--active
			{
				.action-box__panel
				{
					&__content
					{
						display: block !important;
					}
				}
			}
		}
	}
}
