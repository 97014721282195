.scroll-marker
{
	&--main-heading
	{
		.back-to-top
		{
			display: block !important;
		}
	}
}

@media #{$small-only}, #{$medium-only} 
{
	.off-canvas-wrap.move-right
	{
		.back-to-top
		{
			display: none !important;
		}
	}
}
