﻿
$heading-height-small: 50;
$heading-height-large: 100;

$reviews-and-specs-colour: $yellow;
$cars-for-sale-colour: $red;
$car-valuations-colour: $blue;
$tools-colour: $mint-green;

#homePage
{
	.home-page
	{
		&__reviews
		{
			overflow: hidden;
		}
	}

	.action-box
	{
		&__panel
		{
			&__heading
			{
				&__link
				{
					background-repeat: no-repeat;
					padding: 0.75rem 1rem;
				}
			}

			&__content
			{
				&__inner
				{
					.button
					{
						margin-top: 1rem;
					}
				}

				&--reviews-and-specs
				{
					.chevron
					{
						margin-right: 1rem;
						padding-left: 1rem;
						position: absolute;
						right: 0;
						top: 0;
					}
				}
			}

			&--active
			{
				.action-box__panel__heading
				{
					&__link
					{
						cursor: default;
					}
				}
			}
		}
	}

	.logo-slider
	{		
		height: rem-calc(60);

		ul
		{
			li
			{
				display: inline;
			}
		}
	}

	.featured-links
	{
		li
		{
			padding: 0 0 0 1.5rem;
		}
	}

	.manufacturer-logos
	{
		background-color: $white;

		&__logo
		{
			text-align: center;
		}

		&__nav
		{
			@include vertical-align;

			background-color: $blue;
			border-radius: 1rem;
			display: block;
			height: 2rem;
			margin: 2rem auto 0;
			width: 2rem;

			&:hover
			{
				background-color: $cyan;
			}

			&:focus
			{
				outline: 0;
			}

			&--prev
			{
				.manufacturer-logos__nav--inner
				{
					margin-left: -0.05rem;
				}
			}

			&--next
			{
				.manufacturer-logos__nav--inner
				{
					margin-left: 0.05rem;
				}
			}
		}
	}

	button
	{
		&:before
		{
			color: $blue;
			font-size: 2rem;
		}
	}

	.loan-calculator-widget
	{
		.button
		{
			margin-top: 0 !important;
		}
	}

	.featured-reviews
	{
		.quotation
		{
			margin-top: 0.5rem;
		}
	}

	@media #{$small-only}, #{$medium-only}
	{
		.action-box
		{
			&__panel
			{
				background-image: linear-gradient(lighten($background-blue, 5%), lighten($background-blue, 2%));
				border: rem-calc(1) solid $gainsboro;
				border-radius: rem-calc(0 0 5 5);
				border-top: 0;
				box-shadow: inset rem-calc(0 -2 2 -2) $oil;
				margin-bottom: 0.75rem;

				&__heading
				{
					&__link
					{
						background-position: 1rem center;
						border-top: rem-calc(3) solid $tools-colour;
						min-height: rem-calc($heading-height-small);
						padding-left: rem-calc(80);
					}

					&--reviews-and-specs
					{
						.action-box__panel__heading__link
						{
							background-image: url('../images/icons/35x35/read-reviews-yellow.png');
							border-color: $reviews-and-specs-colour;
						}
					}

					&--cars-for-sale
					{
						.action-box__panel__heading__link
						{
							background-image: url('../images/icons/35x35/find-cars-for-sale-red.png');
							border-color: $cars-for-sale-colour;
						}
					}

					&--car-valuations
					{
						.action-box__panel__heading__link
						{
							background-image: url('../images/icons/35x35/car-valuation-blue.png');
							border-color: $car-valuations-colour;
						}
					}

					&--tools
					{
						.action-box__panel__heading__link
						{
							background-image: url('../images/icons/35x35/finance-green.png');
							border-color: $tools-colour;
						}
					}

					&__text
					{
						font-size: 1.3rem;
						margin: 0;
					}
				}

				&__content
				{
					&--reviews-and-specs
					{
						.radio-option__label
						{
							margin-right: 0;
						}
					}

					&__inner
					{
						padding: 0 1rem;

						.button
						{
							margin-top: 0;
						}
					}
				}

				&__expander
				{
					@include clearfix;
					cursor: pointer;
					padding: 0.25rem 0.5rem 0.5rem 0.5rem;
					text-align: center;

					&--reviews-and-specs
					{
						.action-box__panel__expander__icon:before
						{
							color: $reviews-and-specs-colour;
						}
					}

					&--cars-for-sale
					{
						.action-box__panel__expander__icon:before
						{
							color: $cars-for-sale-colour;
						}
					}

					&--car-valuations
					{
						.action-box__panel__expander__icon:before
						{
							color: $car-valuations-colour;
						}
					}

					&--tools
					{
						.action-box__panel__expander__icon:before
						{
							color: $tools-colour;
						}
					}

					&__icon
					{
						&:before
						{
							content: '»';
							display: inline-block;
							font-size: 3rem;
							line-height: 0.1;
							margin-right: 0.5rem;
							position: relative;
							top: 0.25rem;
							transform: rotate(90deg);
						}
					}
				}

				&__right-column
				{
					.action-box__heading
					{
						margin-top: 1rem;
					}
				}
			}
		}
	}

	@media #{$small-up}
	{
		.action-box
		{
			&__panel
			{
				&__expander
				{
					text-align: center;

					&__icon
					{
						&:before
						{
							content: '»';
							display: inline-block;
							font-size: 3rem;
							line-height: 0.1;
							margin-right: 0.5rem;
							position: relative;
							top: 0.25rem;
							transform: rotate(90deg);
						}
					}

					&--reviews-and-specs
					{
						.action-box__panel__expander__icon:before
						{
							color: $reviews-and-specs-colour;
						}
					}

					&--cars-for-sale
					{
						.action-box__panel__expander__icon:before
						{
							color: $cars-for-sale-colour;
						}
					}

					&--car-valuations
					{
						.action-box__panel__expander__icon:before
						{
							color: $car-valuations-colour;
						}
					}

					&--tools
					{
						.action-box__panel__expander__icon:before
						{
							color: $tools-colour;
						}
					}
				}
			}
		}
	}

	@media #{$large-only}
	{
		.inline-ad
		{
			float: right;
			margin-left: rem-calc(10);
			width: rem-calc(300);
		}

		.featured-links
		{
			overflow: hidden;

			li
			{
				padding: 0 0.5rem 0 1rem;
			}
		}

		.action-box__panel__content--reviews-and-specs
		{
			.action-box__panel__column:first-of-type
			{
				margin-bottom: 1rem;
			}
		}
	}

	@media #{$large-up}
	{
		.context-options
		{
			margin-bottom: 0.5rem;
		}

		.action-box
		{
			padding-top: rem-calc($heading-height-large);
			position: relative;

			&__panel
			{
				margin-top: rem-calc(-1);

				&__heading
				{
					background-color: $vapor;
					background-image: linear-gradient($ghost, $smoke);
					border-right: rem-calc(1) solid $gainsboro;
					box-sizing: border-box;
					float: none;
					height: rem-calc($heading-height-large * 0.9);
					left: 0;
					position: absolute;
					top: rem-calc($heading-height-large * 0.1);
					transition: all 400ms cubic-bezier(0, 1, 0.5, 1);
					width: 25%;

					&__link
					{
						background-position: 1rem center;
						border-bottom: rem-calc(1) solid $gainsboro;
						border-top: rem-calc(1) solid $gainsboro;
						height: rem-calc($heading-height-large * 0.9);
						padding-bottom: 0;
						padding-left: rem-calc(65);
						padding-top: 0;
						transition: border-top 400ms 200ms;
					}

					&--reviews-and-specs
					{
						.action-box__panel__heading__link
						{
							background-image: url('../images/icons/35x35/read-reviews-yellow.png');
						}
					}

					&--cars-for-sale
					{
						.action-box__panel__heading__link
						{
							background-image: url('../images/icons/35x35/find-cars-for-sale-red.png');
						}
					}

					&--car-valuations
					{
						.action-box__panel__heading__link
						{
							background-image: url('../images/icons/35x35/car-valuation-blue.png');
						}
					}

					&--tools
					{
						.action-box__panel__heading__link
						{
							background-image: url('../images/icons/35x35/finance-green.png');
						}
					}

					&__text
					{
						@include vertical-align;
						font-size: 1.4rem;
						line-height: 1.1;
						margin: 0;
						text-align: center;
					}
				}

				&:nth-of-type(1)
				{
					.action-box__panel__heading
					{
						border-left: rem-calc(1) solid $gainsboro;
					}
				}

				&:nth-of-type(2)
				{
					.action-box__panel__heading
					{
						left: 25%;
					}
				}

				&:nth-of-type(3)
				{
					.action-box__panel__heading
					{
						left: 50%;
					}
				}

				&:nth-of-type(4)
				{
					.action-box__panel__heading
					{
						left: 75%;
					}
				}

				&__content
				{
					&--reviews-and-specs
					{
						.chevron
						{
							float: right;
							margin: 0;
							padding-left: 1.5rem;
							position: relative;
						}
					}

					&--car-valuations
					{
						.vrm-lookup
						{
							&__input
							{
								margin-top: rem-calc(35);
							}

							&__button
							{
								margin-top: rem-calc(51);
							}
						}
					}

					&__inner
					{
						border: rem-calc(1) solid $gainsboro;
						border-radius: rem-calc(0 0 5 5);
						box-shadow: inset rem-calc(0 -2 2 -2) $oil;
						padding: 1.5rem 1rem;
					}
				}

				&__column
				{
					padding: 0 2rem 0 2rem;

					&--with-divider
					{
						border-left: rem-calc(2) solid $gainsboro;
					}
				}

				&--active
				{
					.action-box__panel
					{
						&__heading
						{
							background-color: $background-blue !important;
							background-image: none;
							border-right: 0;
							height: rem-calc($heading-height-large);
							top: 0;
							transition: all 400ms cubic-bezier(0, 1, 0.5, 1);

							&__link
							{
								border-bottom: 0 !important;
								border-right: rem-calc(1) solid $gainsboro;
								border-top-width: rem-calc(5);
								height: rem-calc($heading-height-large);
								padding-left: rem-calc(80);
								padding-top: 0;
							}
						
							&--reviews-and-specs
							{
								.action-box__panel__heading__link
								{
									background-image: url('../images/icons/50x50/read-reviews-yellow.png');
									border-top-color: $reviews-and-specs-colour;
								}
							}
	
							&--cars-for-sale
							{
								.action-box__panel__heading__link
								{
									background-image: url('../images/icons/50x50/find-cars-for-sale-red.png');
									border-left: rem-calc(1) solid $gainsboro;
									border-top-color: $cars-for-sale-colour;
								}
							}
	
							&--car-valuations
							{
								.action-box__panel__heading__link
								{
									background-image: url('../images/icons/50x50/car-valuation-blue.png');
									border-left: rem-calc(1) solid $gainsboro;
									border-top-color: $car-valuations-colour;
								}
							}
	
							&--tools
							{
								.action-box__panel__heading__link
								{
									background-image: url('../images/icons/50x50/finance-green.png');
									border-left: rem-calc(1) solid $gainsboro;
									border-top-color: $tools-colour;
								}
							}
						}
						
						&__content
						{
							&__inner
							{
								background-image: linear-gradient($background-blue, lighten($background-blue, 5%));
								border-top: 0;
							}
						}
					}
				}
			}

			&--cars-for-sale
			{
				.action-box__panel__heading--reviews-and-specs
				{
					border-right: 0;
				}
			}

			&--car-valuations
			{
				.action-box__panel__heading--cars-for-sale
				{
					border-right: 0;
				}
			}

			&--tools
			{
				.action-box__panel__heading--car-valuations
				{
					border-right: 0;
				}
			}
		}

		.inline-leaderboard-ad
		{
			margin: 1rem auto;
			top: unset;
			transform: unset;
		}
	}

	@media #{$xlarge-up}
	{
		.action-box__panel
		{
			&__column
			{
				&--with-desktop-divider
				{
					border-left: rem-calc(2) solid $gainsboro;
				}
			}
		}
	}
}
