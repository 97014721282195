﻿// latin-ext
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src: local('Montserrat Regular'), local('Montserrat-Regular'), url($font-host-name + '/fonts/montserrat/regular/latin-ext/montserrat-latin-ext.woff2') format('woff2');
  }

// latin
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src: local('Montserrat Regular'), local('Montserrat-Regular'), url($font-host-name + '/fonts/montserrat/regular/latin/montserrat-latin.woff2') format('woff2');
  }

// latin-ext
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	src: local('Montserrat Bold'), local('Montserrat-Bold'), url($font-host-name + '/fonts/montserrat/bold/latin-ext/montserrat-latin-ext.woff2') format('woff2');
  }

// latin
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	src: local('Montserrat Bold'), local('Montserrat-Bold'), url($font-host-name + '/fonts/montserrat/bold/latin/montserrat-latin.woff2') format('woff2');
  }

// latin-ext
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 900;
	src: local('Montserrat Black'), local('Montserrat-Black'), url($font-host-name + '/fonts/montserrat/block/latin-ext/montserrat-latin-ext.woff2') format('woff2');
  }

// latin
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 900;
	src: local('Montserrat Black'), local('Montserrat-Black'), url($font-host-name + '/fonts/montserrat/block/latin/montserrat-latin.woff2') format('woff2');
  }

// latin-ext
@font-face {
	font-family: 'Rajdhani';
	font-style: normal;
	font-weight: 700;
	src: local('Rajdhani Bold'), local('Rajdhani-Bold'), url($font-host-name + '/fonts/rajdhani/bold/latin-ext/rajdhani-latin-ext.woff2') format('woff2');
  }

// latin
@font-face {
	font-family: 'Rajdhani';
	font-style: normal;
	font-weight: 700;
	src: local('Rajdhani Bold'), local('Rajdhani-Bold'), url($font-host-name + '/fonts/rajdhani/bold/latin/rajdhani-latin.woff2') format('woff2');
  }

// latin-ext
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto'), local('Roboto-Regular'), url($font-host-name + '/fonts/roboto/regular/latin-ext/roboto-latin-ext.woff2') format('woff2');
  }

// latin
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto'), local('Roboto-Regular'), url($font-host-name + '/fonts/roboto/regular/latin/roboto-latin.woff2') format('woff2');
  }

// latin-ext
@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 300;
	src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'), url($font-host-name + '/fonts/roboto_condensed/light/latin-ext/roboto-condensed-latin-ext.woff2') format('woff2');
  }

// latin
@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 300;
	src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'), url($font-host-name + '/fonts/roboto_condensed/light/latin/roboto-condensed-latin.woff2') format('woff2');
  }

// latin-ext
@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url($font-host-name + '/fonts/roboto_condensed/regular/latin-ext/roboto-condensed-v25-latin-ext-regular.woff2') format('woff2');
  }

// latin
@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 400;
	src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url($font-host-name + '/fonts/roboto_condensed/regular/latin/roboto-condensed-v25-latin-regular.woff2') format('woff2');
  }

// latin-ext
@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 700;
	src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'), url($font-host-name + '/fonts/roboto_condensed/bold/latin-ext/roboto-condensed-latin-ext.woff2') format('woff2');
  }

// latin
@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 700;
	src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'), url($font-host-name + '/fonts/roboto_condensed/bold/latin/roboto-condensed-latin.woff2') format('woff2');
  }

p
{
	&.collapse
	{
		line-height: 1;
		margin: 0;
	}

	&.small
	{
		font-size: 0.8rem;
		line-height: 1.3;
	}

	&.large
	{
		font-size: 1.1rem;
		line-height: 1.5;
	}

	@media #{$large-up}
	{
		font-size: 0.95rem;
	}
}
