﻿.primary-nav-desktop
{
	background-image: linear-gradient($sky-blue, darken($sky-blue, 5%));
	margin: 0 auto;
	max-height: $tablet-menu-height;
	max-width: $max-site-width;
	overflow: hidden;
	transition: max-height 200ms;

	&--hidden
	{
		max-height: 0;
	}

	.primary-nav-desktop
	{
		&__menu
		{
			@extend %inline-list;

			&__item
			{
				border-right: rem-calc(1) solid $white;
			}

			&__link
			{
				color: $blue;
				display: block;
				font-family: $menu-font;
				font-size: 0.8rem;
				height: $tablet-menu-height;
				line-height: 1.8;
				padding: 0 1rem;
				text-transform: uppercase;
				transition: background-color 200ms;

				&:hover
				{
					background-color: $cyan;
					color: $white;
				}

				&--more-menu:after,
				&--has-sub-menu:after
				{
					@include triangle('down', rem-calc(6), rem-calc(6), $blue);
					margin: rem-calc(2) auto 0 auto;
				}

				&--more-menu:hover:after,
				&--has-sub-menu:hover:after
				{
					border-top-color: $white;
				}

				&--more-menu
				{
					border-right: rem-calc(1) solid $white;
					float: right;
				}
			}
		}

		&__sub-menu
		{
			background-color: $background-blue;
			border: rem-calc(1) solid $iron;
			border-top: 0;
			box-shadow: rem-calc(2 2 2) $tuatara;
			display: none;
			position: absolute;
			z-index: z-index(90);

			&__item
			{
				&:last-child > *
				{
					border-bottom: 0;
				}
			}

			&__link
			{
				border-bottom: rem-calc(1) solid $white;
				color: $blue;
				display: block;
				font-family: $menu-font;
				font-size: 0.9rem;
				height: $tablet-sub-menu-item-height;
				line-height: $tablet-sub-menu-item-height;
				padding: 0 1rem;
				text-transform: none;
				transition: background-color 200ms;

				&:hover
				{
					background-color: $cyan;
					color: $white;
				}
			}
		}
	}

	@media #{$xlarge-up}
	{
		max-height: $desktop-menu-height !important;

		.primary-nav-desktop
		{
			&__menu__link
			{
				font-size: 0.9rem;
				height: $desktop-menu-height;
				line-height: 2.2;

				&--more-menu:after,
				&--has-sub-menu:after
				{
					float: right;
					margin: rem-calc(12 0 0 8);
				}
			}

			&__sub-menu__link
			{
				height: $desktop-sub-menu-item-height;
				line-height: $desktop-sub-menu-item-height;
			}
		}
	}
}
