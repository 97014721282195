﻿.screen-size-detector
{
	display: none;
	font-family: S;
	left: 0;
	position: fixed;
	top: 0;

	&:after
	{
		background-color: $black;
		color: $white;
		content: 'Small';
		font-family: sans-serif;
		padding: 0.5rem 1rem;
	}

	@media #{$medium-up}
	{
		font-family: M;

		&:after
		{
			content: 'Medium';
		}
	}

	@media #{$large-up}
	{
		font-family: L;

		&:after
		{
			content: 'Large';
		}
	}

	@media #{$xlarge-up}
	{
		font-family: XL;

		&:after
		{
			content: 'X-Large';
		}
	}

	@media #{$xxlarge-up}
	{
		font-family: XXL;

		&:after
		{
			content: 'XX-Large';
		}
	}
}
