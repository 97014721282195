.site-search
{
	.site-search
	{
		&__form
		{
			margin: 0;
		}

		&__input
		{
			@include background-icon-35x35-magnifying-glass-blue;
			box-shadow: none;
			font-size: 0.9rem;
			margin: 0;
			padding: 0.25rem 0.25rem 0.25rem 2rem;

			&:focus
			{
				@include background-icon-35x35-magnifying-glass-blue;
				box-shadow: none;
			}
		}

		&__button
		{
			background-color: $dark-blue;
			border: 0;
			box-shadow: rem-calc(2 3 0 0) $oil;
			color: $white;
			font-family: $button-font;
			font-size: 1.1rem;
			line-height: 2.2;
			margin: 0;
			outline: none;
			padding: 0;
			position: relative;
			text-align: center;
			text-transform: uppercase;
			transition: all 200ms;
			width: 100%;

			&:hover
			{
				background-color: $cyan;
			}
		}
	}
}

.site-search-mobile
{
	margin-bottom: 0.5rem;
	padding: 0 0.4rem 0 0.2rem;

	.site-search-mobile
	{
		&__input
		{
			@include background-icon-32x32-magnifying-glass-blue;
			font-size: 0.8rem;
			height: 2rem;
			padding: 0.1rem 0.1rem 0.1rem 2rem;

			&:focus
			{
				@include background-icon-32x32-magnifying-glass-blue;
			}
		}

		&__button
		{
			background-color: $dark-blue;
			height: 2rem;
			line-height: 2rem;
		}
	}
}

.site-search-tablet
{
	@include slide-hidden();

	&--expanded
	{
		@include slide-visible(rem-calc(51));
	}

	.site-search-tablet
	{
		&__inner
		{
			background-color: $background-blue;
			padding: 0.25rem 0.6rem 0.6rem 0.6rem;
		}

		&__button
		{
			background-color: $cyan;

			&:hover
			{
				background-color: $sky-blue;
			}
		}
	}
}

.site-search-desktop
{
	box-sizing: content-box;
	display: none;
	float: right;
	margin-left: 0.5rem;
	padding: 0.5rem 1rem 0.5rem 0.5rem;

	.site-search-desktop
	{
		&__button
		{
			font-size: 1rem;
		}
	}

	@media #{$xlarge-up}
	{
		display: block;
	}
}
