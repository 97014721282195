﻿
@import 'foundation/functions';

// paths
$images-path: '../images';
$bower-path: '../../../../bower_components';

// master page
$base-z-index: 1000;

$max-site-width-value: 1400;
$max-site-width: rem-calc($max-site-width-value);

$mobile-header-height-value: 45;
$mobile-header-height: rem-calc($mobile-header-height-value);

$mobile-ad-notification-height-value: 32;
$mobile-ad-notification-height: rem-calc($mobile-ad-notification-height-value);

$mobile-sub-menu-item-height-value: 36;
$mobile-sub-menu-item-height: rem-calc($mobile-sub-menu-item-height-value);

$tablet-header-height-value: 55;
$tablet-header-height: rem-calc($tablet-header-height-value);

$tablet-header-border-width-value: 5;
$tablet-header-border-width: rem-calc($tablet-header-border-width-value);

$tablet-menu-height-value: 45;
$tablet-menu-height: rem-calc($tablet-menu-height-value);

$tablet-sub-menu-item-height-value: 28;
$tablet-sub-menu-item-height: rem-calc($tablet-sub-menu-item-height-value);

$tablet-left-sidebar-width-value: 266;
$tablet-left-sidebar-width: rem-calc($tablet-left-sidebar-width-value);

$tablet-content-offset-value: $tablet-header-height-value + $tablet-header-border-width-value + $tablet-menu-height-value;
$tablet-content-offset: rem-calc($tablet-content-offset-value);

$billboard-top-advert-container-height-value: 250;
$billboard-top-advert-container-padding-value: 16;

$tablet-top-advert-container-height-value: 90;
$tablet-top-advert-container-padding-value: 16;

$mobile-top-advert-container-height-value: 100;
$mobile-top-advert-container-padding-value: 4;
$mobile-top-advert-timer-height-value: 16;

$desktop-header-inner-height-value: 55;
$desktop-header-inner-height: rem-calc($desktop-header-inner-height-value);

$desktop-header-border-width-value: 5;
$desktop-header-border-width: rem-calc($desktop-header-border-width-value);

$desktop-menu-height-value: 35;
$desktop-menu-height: rem-calc($desktop-menu-height-value);

$desktop-sub-menu-item-height-value: 28;
$desktop-sub-menu-item-height: rem-calc($desktop-sub-menu-item-height-value);

$desktop-left-sidebar-width-value: 250;
$desktop-left-sidebar-width: rem-calc($desktop-left-sidebar-width-value);

$desktop-footer-height-value: 170;
$desktop-footer-height: rem-calc($desktop-footer-height-value);

$desktop-header-height-value: $desktop-header-inner-height-value + $desktop-header-border-width-value + $desktop-menu-height-value;
$desktop-header-height: rem-calc($desktop-header-height-value);

$desktop-menu-text-wrap-allowance-value: 0;

$desktop-content-offset-value: $desktop-header-inner-height-value + $desktop-header-border-width-value + $desktop-menu-height-value + $desktop-menu-text-wrap-allowance-value;
$desktop-content-offset: rem-calc($desktop-content-offset-value);

$billboard-min-page-width-value: 1050;
$billboard-min-page-width: rem-calc($billboard-min-page-width-value);

$desktop-button-width: 50%;

$raised-footer-height-value: 70;
$raised-footer-height: rem-calc($raised-footer-height-value);

$raised-footer-text-spacing-height-value: 5;
$raised-footer-text-spacing-height: rem-calc($raised-footer-text-spacing-height-value);

// colors
$blue: #034EA2; // Also used for $primary-color
$cyan: #53B1E7;
$pale-cyan: #CAF4F4;
$sky-blue: #BDD8FD;
$background-blue: #E0ECFE;
$lightened-background-blue: #F4F8FF;
$dark-blue: #01336A;
$slate: #374250;
$childs-play-sky: #9AE0E2;

$orange: #FDC600;
$green: #749100;
$mint-green: #00A472;
$red: #D52E20;
$yellow: #FFD500;
$background-yellow: #FFFEAC;

$car-finance-247-purple: #211850;
$car-finance-247-green: #9DD033;
$car-finance-247-blue: #06AEFF;

$transparent-grey: rgba(0, 0, 0, 0.5);

$choose-package-gold-gradient: linear-gradient(to bottom,
	rgb(253, 247, 229) 40%,
	rgb(253, 236, 187) 50%,
	rgb(251, 226, 167) 55%,
	rgb(253, 243, 213) 80%);
$choose-package-silver-gradient: linear-gradient(to bottom,
	rgb(243, 243, 243) 0%,
	rgb(241, 241, 241) 50%,
	rgb(237, 237, 237) 60%,
	rgb(255, 255, 255) 90%);
$choose-package-bronze-gradient: linear-gradient(to bottom,
	rgb(201, 190, 173) 10%,
	rgb(247, 233, 210) 50%,
	rgb(230, 213, 186) 80%);

// social media colors
$facebook-colour: #3B5998;
$twitter-colour: #1DACED;
$youtube-colour: #DB4A39;

// fonts
$body-font: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$heading-font: 'Roboto Condensed', $body-font;
$sub-heading-font: 'Roboto', $body-font;

$button-font: 'Roboto Condensed', $body-font;
$icon-font: 'foundation-icons', $body-font;
$tab-font: 'Roboto Condensed', $body-font;

$menu-font: 'Roboto Condensed', $body-font;
$menu-heading-font: 'Roboto Condensed', $body-font;
$menu-sub-heading-font: 'Roboto', $body-font;

$back-to-top-font: 'Roboto', $body-font;
$breadcrumb-font: 'Roboto Condensed', $body-font;

$caption-panel-font: $body-font;
$caption-panel-heading-font: 'Roboto Condensed', $body-font;

$content-box-font: $body-font;
$content-box-heading-font: 'Roboto Condensed', $body-font;
$content-box-heading-font-size: 1.4rem;

$info-box-font: 'Roboto', $body-font;
$info-box-heading-font: 'Roboto Condensed', $body-font;
$info-box-heading-font-size: 1.4rem;

$link-list-font: $body-font;
$panel-font: 'Roboto', $body-font;
$trim-derivative-list-font: 'Verdana', 'Geneva', sans-serif;
$process-step-font: 'Roboto Condensed', $body-font;

$body-style-font: 'Roboto Condensed', $body-font;
$vehicle-selector-font: 'Roboto Condensed', $body-font;
$vehicle-selector-heading-font: 'Roboto', $body-font;
$euroncap-font: 'Roboto', $body-font;
$gallery-font: 'Roboto Condensed', $body-font;
$quick-links-heading-font: 'Roboto Condensed', $body-font;
$quick-links-sub-heading-font: 'Roboto', $body-font;
$quotation-font: 'Roboto Condensed', $body-font;
$search-filters-font: 'Roboto Condensed', $body-font;
$vrm-font: 'Rajdhani', $body-font;

$car-finance-247-font-family: 'Montserrat', $body-font;

// icons
$cross-icon: '\f217';

$medium-icon-height-value: 24;
$medium-icon-height: rem-calc($medium-icon-height-value);

// misc
$negative-indent: rem-calc(-9999);

//font url
$font-host-name: #{$host-name};
