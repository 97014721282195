﻿.site-header-desktop
{
	background: $white;
	cursor: default;
	display: none;
	width: 100%;
	z-index: z-index(4);

	&--retracted
	{
		background: none;
		left: 0;
		position: fixed;
		top: 0;	
		transform: translateY(-$tablet-content-offset);
		
		&.site-header-desktop--revealed
		{
			transform: none;
		}
	}

	&--transition
	{
		transition: transform 0.7s;
	}

	.site-header-desktop
	{
		&__inner
		{
			background-image: linear-gradient(lighten($blue, 5%), $blue);
			border-bottom: $tablet-header-border-width solid $dark-blue;
			height: rem-calc($tablet-header-height-value + $tablet-header-border-width-value);
			margin: 0 auto;
			max-width: $max-site-width;
		}

		&__logo
		{
			color: $white;
			float: left;
			height: $tablet-header-height;
			width: rem-calc(200 + $tablet-header-height-value + 10);

			&:hover
			{
				.site-header-desktop__logo__icon
				{
					background-color: $cyan;
					color: $white;
				}
			}

			&__icon
			{
				display: inline-block;
				font-size: 2rem;
				height: $tablet-header-height;
				line-height: $tablet-header-height;
				text-align: center;
				transition: background-color 200ms;
				width: $tablet-header-height;
			}

			&__image
			{
				margin: 0 0 0.8rem 0.3rem;
			}
		}
	}

	@media #{$xlarge-up}
	{
		&--retracted
		{
			transform: translateY(-$desktop-header-height);
			
			&.site-header-desktop--revealed
			{
				transform: none;
			}
		}
	}

	@media #{$large-up}
	{
		display: block;
	}
}

