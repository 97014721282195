﻿.site-footer
{
	$social-link-size: 50;

	background-image: linear-gradient($steel, $charcoal);
	margin: rem-calc(0 -1 0 -1);
	min-height: rem-calc(100);
	padding: 1rem 0 2rem 0;

	> .row
	{
		margin: 0;
	}

	.site-footer
	{
		&__bauer-logo
		{
			float: left;
			margin-right: 1rem;
		}

		&__copyright,
		&__company-info
		{
			color: $smoke;
			font-size: 0.8rem;
			margin: 1rem 0 0 0;
		}

		&__company-info
		{
			font-size: 0.7rem;
		}
	}

	.social-nav
	{
		margin: 0 auto 1rem auto;
		width: rem-calc(($social-link-size + 8) * 4);

		.social-nav
		{
			&__link
			{
				border-radius: rem-calc($social-link-size / 2);	
				font-size: 2rem;
				height: rem-calc($social-link-size);
				line-height: rem-calc($social-link-size);
				width: rem-calc($social-link-size);		
			}
		}
	}

	@media #{$small-only}, #{$medium-only}
	{
		padding-bottom: 2rem;

		&--raised
		{
			padding-bottom: $raised-footer-height + $raised-footer-text-spacing-height;
		}
	}

	@media #{$large-up}
	{
		padding-bottom: 1rem;

		.social-nav
		{
			float: right;
			width: auto;
		}

		&--raised
		{
			padding-bottom: $raised-footer-height + $raised-footer-text-spacing-height;
		}
	}

	@media #{$xlarge-up}
	{
		min-height: $desktop-footer-height;
	}
}
