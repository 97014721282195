.tooltip
{
	display: inline-block;
	position: relative;

	&__text
	{
		background-color: $smoke;
		border-radius: 0.2rem;
		color: $black;
		display: none;
		margin: rem-calc(30) 0 0 rem-calc(-205);
		padding: 1rem;
		position: absolute;
		text-align: center;
		width: rem-calc(260);
		z-index: 1;
	}

	&--displayed
	{
		.tooltip__text
		{
			display: block;
		}
	}
}
