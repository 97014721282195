﻿.more-menu-desktop
{
	background-color: $white;
	border: rem-calc(1) solid $base;
	border-top: 0;
	box-shadow: rem-calc(2 2 2) rgba(0, 0, 0, 0.6);
	display: none;
	left: 1rem;
	padding: 0.5rem 1rem 1rem 1rem;
	position: absolute;
	top: rem-calc($tablet-header-height-value + $tablet-header-border-width-value + $tablet-menu-height-value);
	width: 96%;
	z-index: z-index(100);

	.more-menu-desktop
	{
		&__heading
		{
			font-family: $menu-font;
			font-size: 1.4rem;
			font-weight: normal;
			margin: 0 0 0.5rem 0;
		}

		&__menu
		{
			display: flex;

			&__item
			{
				border-left: rem-calc(1) solid $iron;
				padding: 0 1rem;
			}

			&__link
			{
				color: $blue;
				display: block;
				font-family: $menu-sub-heading-font;
				font-size: 0.9rem;
				font-weight: bold;
				text-transform: uppercase;

				&:hover
				{
					color: $cyan;
				}
			}
		}

		&__sub-menu
		{
			list-style: none;
			margin: 0;
			padding: 0;

			&__item
			{
				display: block;
			}

			&__link
			{
				color: $blue;
				font-family: $menu-sub-heading-font;
				font-size: 0.9rem;
				height: auto;
				text-transform: none;

				&:hover
				{
					color: $cyan;
				}
			}
		}
	}

	@media #{$xlarge-up}
	{
		top: $desktop-content-offset;
	}
}
