﻿
$site-nav-badge-size: 28;

.site-nav
{
	box-sizing: border-box;
	float: right;

	.site-nav
	{
		&__link
		{
			background-position: 0.5rem center;
			background-repeat: no-repeat;
			color: $white;
			float: left;
			font-family: $menu-font, $body-font-family;
			font-size: 0.875rem;
			height: $tablet-header-height;
			line-height: $tablet-header-height;
			min-width: 4rem;
			transition: background-color 200ms;

			&:hover
			{
				background-color: $cyan;
				color: $white;
			}

			&--active
			{
				background-color: $dark-blue;
				color: $white;
			}

			&__icon
			{
				display: inline-block;
				margin: 0 0.25rem 0 0;
			}
		
			&__label
			{
				vertical-align: top;
			}

			
			&__badge
			{
				background-color: $dark-blue;
				border-radius: rem-calc($site-nav-badge-size/2);
				color: $white;
				display: inline-block;
				float: right;
				font-family: $heading-font;
				font-size: 1.1rem;
				font-weight: bold;
				line-height: rem-calc($site-nav-badge-size);
				margin: rem-calc(13) 0 0 rem-calc(6);
				text-align: center;
				transition: background-color 1s ease;
				width: rem-calc($site-nav-badge-size);

				&--added,
				&--removed
				{
					animation: fadeIn 800ms alternate infinite;
				}

				&--added
				{
					background-color: $green;
				}

				&--removed
				{
					background-color: $red;
				}
			}

			&--cars
			{
				padding: 0 0.75rem;
			}

			&--vans
			{
				padding: 0 0.75rem;
			}

			&--saved-cars
			{
				padding: 0 0.75rem;
			}

			&--site-search
			{
				&__icon
				{
					display: block;
					margin: 4px auto;
				}

				&__label
				{
					text-indent: $negative-indent;
				}
			}
		}
	}
}
