.sticky-off-canvas-bottom
{
	border-top: rem-calc(1) solid $black;
	font-family: $button-font;
	padding: 0.5rem;
	position: relative;
	text-align: center;

	&__button
	{
		background-color: $blue;
		border: rem-calc(2) solid $blue;
		border-radius: 0.3rem;
		color: $white;
		font-size: 0.8rem;
		padding: 0.5rem 0;
		width: 11rem;

		&--disabled
		{
			font-size: 1rem;
		}
	}

	&__result
	{
		display: block;
		margin: 0 auto;
	}

	&__spinner
	{
		animation: spin 1s infinite linear;
		border: 0.25rem solid $white;
		border-radius: 50%;
		border-top-color: $blue;
		float: left;
		height: 1.5rem;
		margin-left: 0.5rem;
		width: 1.5rem;
	}

	&__count
	{
		font-size: 1rem;
	}
	 		
	@media #{$large-up}
	{
		display: none;
	}
}

