﻿.site-root
{
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: $max-site-width;
	min-height: 100vh;
	position: relative;
}

.site-content
{
	background-color: $white;
	flex-grow: 1;
	min-height: 100%;

	&--retracted
	{
		margin-top: $mobile-header-height;
	}

	&__top
	{
		border-bottom: rem-calc(1) solid $smoke;
	}
}

.site-main
{
	padding: 0.75rem 0 1rem 0;

	.taboolaContainer {
		padding: 0 2rem;
	}
}

.site-sidebar-left
{
	display: none;

	&.left-off-canvas-menu
	{
		display: block;
	}
}

@media #{$small-only}, #{$medium-only}, #{$large-only}
{
	.site-content
	{
		position: relative;
		transition: transform 500ms;
	}

	.off-canvas-wrap.move-right
	{
		> .inner-wrap
		{
			transform: none;
		}

		.exit-off-canvas--mobile
		{
			display: none;
		}
	}

	.left-off-canvas-menu
	{
		overflow: visible;
	}

	.move-right .exit-off-canvas
	{
		display: none;
	}
}

@media #{$small-only}, #{$medium-only} 
{
	.off-canvas-wrap.move-right
	{
		.site-content
		{
			transform: translate3d(100%, 0, 0);
		}
	}

	.left-off-canvas-menu
	{
		width: 100%;
	}
}

@media #{$large-only}
{
	.site-content--retracted
	{
		margin-top: $tablet-content-offset;
	}

	.off-canvas-wrap.move-right
	{
		.site-content
		{
			transform: translate3d($off-canvas-width, 0, 0);
		}
	}
}

@media #{$large-up}
{	
	.site-content--retracted
	{
		margin-top: $desktop-content-offset;
	}
}

@media #{$xlarge-up}
{
	$border-color: #D4D4D4;

	.site-content
	{
		&__middle
		{
			display: flex;
		}
	}

	.site-main
	{
		flex-basis: 60%;
		flex-grow: 1;
	}

	.site-sidebar-left
	{
		box-sizing: inherit;
		display: none;
		flex-basis: $desktop-left-sidebar-width;
		min-width: $desktop-left-sidebar-width;
		overflow: inherit;
		transform: inherit;
		transition: inherit;
	}

	.site-root
	{
		border-left: rem-calc(1) solid $border-color;
		border-right: rem-calc(1) solid $border-color;
	}
}

@media #{$xlarge-only}
{
	.site-content
	{
		&--has-search-filters
		{
			.site-sidebar-left
			{
				display: block;
			}
		}
	}
}
