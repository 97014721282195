﻿
$off-canvas-menu-badge-size: 30;

.off-canvas-menu-mobile
{
	.off-canvas-menu-mobile
	{
		&__header
		{
			background: $white;
			border-bottom: rem-calc(5) solid $dark-blue;
			box-sizing: border-box;
			padding-left: rem-calc(2);

			&__close-link
			{
				color: $dark-blue;
				float: right;
				font-family: $breadcrumb-font;
				font-size: 1rem;
				margin-bottom: 0.4rem;
				padding: 0.5rem 1rem;
				position: relative;
				z-index: 10;
			}
		}

		&__tabs
		{
			@extend %inline-list;
		}

		&__tab
		{
			&__title
			{
				margin: 0 rem-calc(2);

				&.active
				{
					.off-canvas-menu-mobile__tab__link
					{
						background-color: $dark-blue;
						height: rem-calc(90);
					}
				}
			}

			&__link
			{
				background-color: $blue;
				background-position: center top;
				background-repeat: no-repeat;
				border-radius: 0 rem-calc(10) 0 0;
				color: $white;
				display: block;
				font-family: $menu-font, $body-font-family;
				font-size: 1rem;
				height: rem-calc(90);
				line-height: 1;
				min-width: rem-calc(80);
				padding: 0 0.5rem 0.5rem 0.5rem;
				text-align: center;
				transition: background-color 200ms;

				&:hover
				{
					background-color: $off-canvas-bg-hover;
				}

				&__icon
				{
					display: block;
					margin: 0 auto;
				}

				&__label-small
				{
					font-size: 0.7rem;
				}

				&__badge
				{
					background-color: $dark-blue;
					border-radius: rem-calc($off-canvas-menu-badge-size/2);
					color: $white;
					display: block;
					font-family: $heading-font;
					font-size: 1rem;
					font-weight: bold;
					line-height: rem-calc($off-canvas-menu-badge-size);
					margin: 0 auto;
					text-align: center;
					width: rem-calc($off-canvas-menu-badge-size);

					&__container
					{
						display: block;
						margin: 0 auto;
						padding: rem-calc(10) 0;
					}
				}
			}
		}

		&__tab-content
		{
			box-sizing: border-box;
			padding-top: 0;
		}
	}
}
