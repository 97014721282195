.story-block
{	
	&__image
	{
		display: block;
		margin: 0 0 0.5rem 0;
	}

	&__heading
	{
		font-size: 1.1rem;
		font-weight: normal;
		margin: 0;
	}
}
