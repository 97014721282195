@mixin inactive-body-styles
{
	&--small-city
	{
		@include bodystyle-small-city-grey;
	}

	&--hatchback
	{
		@include bodystyle-hatchback-grey;
	}

	&--saloon
	{
		@include bodystyle-saloon-grey;
	}

	&--estate
	{
		@include bodystyle-estate-grey;
	}

	&--mpv-people-carrier
	{
		@include bodystyle-mpv-people-carrier-grey;
	}

	&--suv
	{
		@include bodystyle-suv-grey;
	}

	&--coupe
	{
		@include bodystyle-coupe-grey;
	}

	&--convertible-roadster
	{
		@include bodystyle-convertible-roadster-grey;
	}

	&--4x4
	{
		@include bodystyle-4x4-grey;
	}

	&--family
	{
		@include bodystyle-family-grey;
	}

	&--fast-sports
	{
		@include bodystyle-fast-sports-grey;
	}

	&--electric-hybrid
	{
		@include bodystyle-electric-hybrid-grey;
	}

	&--car-van
	{
		@include bodystyle-car-van-grey;
	}

	&--small-van
	{
		@include bodystyle-small-van-grey;
	}

	&--commercial-4x4
	{
		@include bodystyle-commercial-4x4-grey;
	}

	&--pickup
	{
		@include bodystyle-pickup-grey;
	}

	&--medium-van
	{
		@include bodystyle-medium-van-grey;
	}

	&--large-van
	{
		@include bodystyle-large-van-grey;
	}

	&--minibus
	{
		@include bodystyle-minibus-grey;
	}

	&--conversion
	{
		@include bodystyle-conversion-grey;
	}

	&--light-truck
	{
		@include bodystyle-light-truck-grey;
	}
}

@mixin active-body-styles
{
	&--small-city
	{
		@include bodystyle-small-city-black;
	}

	&--hatchback
	{
		@include bodystyle-hatchback-black;
	}

	&--saloon
	{
		@include bodystyle-saloon-black;
	}

	&--estate
	{
		@include bodystyle-estate-black;
	}

	&--mpv-people-carrier
	{
		@include bodystyle-mpv-people-carrier-black;
	}

	&--suv
	{
		@include bodystyle-suv-black;
	}

	&--coupe
	{
		@include bodystyle-coupe-black;
	}

	&--convertible-roadster
	{
		@include bodystyle-convertible-roadster-black;
	}

	&--4x4
	{
		@include bodystyle-4x4-black;
	}

	&--family
	{
		@include bodystyle-family-black;
	}

	&--fast-sports
	{
		@include bodystyle-fast-sports-black;
	}

	&--electric-hybrid
	{
		@include bodystyle-electric-hybrid-black;
	}

	&--car-van
	{
		@include bodystyle-car-van-black;
	}

	&--small-van
	{
		@include bodystyle-small-van-black;
	}

	&--commercial-4x4
	{
		@include bodystyle-commercial-4x4-black;
	}

	&--pickup
	{
		@include bodystyle-pickup-black;
	}

	&--medium-van
	{
		@include bodystyle-medium-van-black;
	}

	&--large-van
	{
		@include bodystyle-large-van-black;
	}

	&--minibus
	{
		@include bodystyle-minibus-black;
	}

	&--conversion
	{
		@include bodystyle-conversion-black;
	}

	&--light-truck
	{
		@include bodystyle-light-truck-black;
	}
}

@mixin selected-body-styles
{
	&--small-city
	{
		@include bodystyle-small-city-white;
	}

	&--hatchback
	{
		@include bodystyle-hatchback-white;
	}

	&--saloon
	{
		@include bodystyle-saloon-white;
	}

	&--estate
	{
		@include bodystyle-estate-white;
	}

	&--mpv-people-carrier
	{
		@include bodystyle-mpv-people-carrier-white;
	}

	&--suv
	{
		@include bodystyle-suv-white;
	}

	&--coupe
	{
		@include bodystyle-coupe-white;
	}

	&--convertible-roadster
	{
		@include bodystyle-convertible-roadster-white;
	}

	&--4x4
	{
		@include bodystyle-4x4-white;
	}

	&--family
	{
		@include bodystyle-family-white;
	}

	&--fast-sports
	{
		@include bodystyle-fast-sports-white;
	}

	&--electric-hybrid
	{
		@include bodystyle-electric-hybrid-white;
	}

	&--car-van
	{
		@include bodystyle-car-van-white;
	}

	&--small-van
	{
		@include bodystyle-small-van-white;
	}

	&--commercial-4x4
	{
		@include bodystyle-commercial-4x4-white;
	}

	&--pickup
	{
		@include bodystyle-pickup-white;
	}

	&--medium-van
	{
		@include bodystyle-medium-van-white;
	}

	&--large-van
	{
		@include bodystyle-large-van-white;
	}

	&--minibus
	{
		@include bodystyle-minibus-white;
	}

	&--conversion
	{
		@include bodystyle-conversion-white;
	}

	&--light-truck
	{
		@include bodystyle-light-truck-white;
	}
}

@mixin disabled-body-styles
{
	&--small-city
	{
		@include bodystyle-small-city-light-grey;
	}

	&--hatchback
	{
		@include bodystyle-hatchback-light-grey;
	}

	&--saloon
	{
		@include bodystyle-saloon-light-grey;
	}

	&--estate
	{
		@include bodystyle-estate-light-grey;
	}

	&--mpv-people-carrier
	{
		@include bodystyle-mpv-people-carrier-light-grey;
	}

	&--suv
	{
		@include bodystyle-suv-light-grey;
	}

	&--coupe
	{
		@include bodystyle-coupe-light-grey;
	}

	&--convertible-roadster
	{
		@include bodystyle-convertible-roadster-light-grey;
	}

	&--4x4
	{
		@include bodystyle-4x4-light-grey;
	}

	&--family
	{
		@include bodystyle-family-light-grey;
	}

	&--fast-sports
	{
		@include bodystyle-fast-sports-light-grey;
	}

	&--electric-hybrid
	{
		@include bodystyle-electric-hybrid-light-grey;
	}

	&--car-van
	{
		@include bodystyle-car-van-light-grey;
	}

	&--small-van
	{
		@include bodystyle-small-van-light-grey;
	}

	&--commercial-4x4
	{
		@include bodystyle-commercial-4x4-light-grey;
	}

	&--pickup
	{
		@include bodystyle-pickup-light-grey;
	}

	&--medium-van
	{
		@include bodystyle-medium-van-light-grey;
	}

	&--large-van
	{
		@include bodystyle-large-van-light-grey;
	}

	&--minibus
	{
		@include bodystyle-minibus-light-grey;
	}

	&--conversion
	{
		@include bodystyle-conversion-light-grey;
	}

	&--light-truck
	{
		@include bodystyle-light-truck-light-grey;
	}
}
