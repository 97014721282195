﻿@mixin arrow($direction, $arrowSize, $lineThickness, $colour, $vertical-align: false)
{
	border-bottom: $lineThickness solid $colour;
	border-right: $lineThickness solid $colour;
	content: ' ';
	display: inline-block;
	height: 0;
	padding: $arrowSize;
	width: 0;

	@if $vertical-align == true
	{
		position: absolute;
		top: 50%;

		@if $direction == 'left'
		{
			transform: translateY(-50%) rotate(135deg);
		}
		@else if $direction == 'right'
		{
			transform: translateY(-50%) rotate(-45deg);
		}
		@else if $direction == 'up'
		{
			transform: translateY(-50%) rotate(-135deg);
		}
		@else if $direction == 'down'
		{
			transform: translateY(-50%) rotate(45deg);
		}
	}
	@else
	{
		@if $direction == 'left'
		{
			transform: rotate(135deg);
		}
		@else if $direction == 'right'
		{
			transform: rotate(-45deg);
		}
		@else if $direction == 'up'
		{
			transform: rotate(-135deg);
		}
		@else if $direction == 'down'
		{
			transform: rotate(45deg);
		}
	}
}

@mixin triangle($direction, $baseWidth, $pointLength, $colour)
{
	content: '';
	display: block;
	height: 0;
	width: 0;

	@if $direction == 'left'
	{
		border-bottom: $baseWidth solid transparent;
		border-right: $pointLength solid $colour;
		border-top: $baseWidth solid transparent;
	}
	@else if $direction == 'right'
	{
		border-bottom: $baseWidth solid transparent;
		border-left: $pointLength solid $colour;
		border-top: $baseWidth solid transparent;
	}
	@else if $direction == 'up'
	{
		border-bottom: $pointLength solid $colour;
		border-left: $baseWidth solid transparent;
		border-right: $baseWidth solid transparent;
	}
	@else if $direction == 'down'
	{
		border-left: $baseWidth solid transparent;
		border-right: $baseWidth solid transparent;
		border-top: $pointLength solid $colour;
	}
	@else
	{
		@error 'Direction not recognised.';
	}
}

@mixin slide-hidden($duration: 500ms, $property: max-height, $function: ease)
{
	max-height: 0;
	overflow-y: hidden;
	transition-duration: $duration;
	transition-property: $property;
	transition-timing-function: $function;
}

@mixin slide-visible($max-height: 500px)
{
	max-height: $max-height;
}

// scss-lint:disable DuplicateProperty
@mixin transparent-bg($color, $opacity: 0.7)
{
	$rgba: rgba($color, $opacity);
	$ie-hex-str: ie-hex-str($rgba);
	background-color: transparent;
	background-color: $rgba;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$ie-hex-str},endColorstr=#{$ie-hex-str});
	zoom: 1;
}

@mixin vertical-align
{
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

@mixin zoom
{
	cursor: pointer;
	cursor: zoom-in;
}

@mixin horizontal-scrollbar($track-color: $white-smoke, $thumb-color: $cyan, $width: 0.4rem, $height: 0.6rem, $scrollbar-bkgcolor: $white)
{
	overflow-x: auto;
	white-space: nowrap;

	&::-webkit-scrollbar-track
	{
		background-color: $track-color;
		border-radius: 0.4rem;
		box-shadow: inset 0 0 0.3rem rgba($black, 0.3);
		width: $width;
	}

	&::-webkit-scrollbar-thumb
	{
		background-color: $thumb-color;
		border-radius: 0.4rem;
		box-shadow: inset 0 0 0.3rem rgba($black, 0.3);
	}

	&::-webkit-scrollbar
	{
		background-color: $scrollbar-bkgcolor;
		height: $height;
		width: $width;
	}
}

@mixin vertical-scrollbar($track-color: $white-smoke, $thumb-color: $cyan, $width: 0.4rem, $height: 0.6rem, $scrollbar-bkgcolor: $white)
{
	overflow-y: auto;
	
	&::-webkit-scrollbar-track
	{
		background-color: $track-color;
		border-radius: 0.4rem;
		box-shadow: inset 0 0 0.3rem rgba($black, 0.3);
		width: $width;
	}

	&::-webkit-scrollbar-thumb
	{
		background-color: $thumb-color;
		border-radius: 0.4rem;
		box-shadow: inset 0 0 0.3rem rgba($black, 0.3);
	}

	&::-webkit-scrollbar
	{
		background-color: $scrollbar-bkgcolor;
		height: $height;
		width: $width;
	}
}
