.margin-left
{
	display: inline-block;
	margin-left: 1rem;

	&--small
	{
		margin-left: 0.5rem;
	}

	&--tiny
	{
		margin-left: 0.2rem;
	}
}

.margin-right
{
	display: inline-block;
	margin-right: 1rem;

	&--small
	{
		margin-right: 0.5rem;
	}

	&--tiny
	{
		margin-right: 0.2rem;
	}
}

.margin-top
{
	display: inline-block;
	margin-top: 1rem;

	&--small
	{
		margin-top: 0.5rem;
	}

	&--tiny
	{
		margin-top: 0.2rem;
	}
}

.margin-bottom
{
	display: inline-block;
	margin-bottom: 1rem;

	&--small
	{
		margin-bottom: 0.5rem;
	}

	&--tiny
	{
		margin-bottom: 0.2rem;
	}
}
