﻿.footer-nav
{
	@extend %inline-list;
	line-height: 1;
	margin-bottom: 1rem;

	.footer-nav
	{
		&__item
		{
			margin-right: 0.75rem;
		}

		&__link
		{
			color: $white;
			font-size: 0.75rem;
			font-weight: bold;
			text-transform: uppercase;

			&:hover
			{
				color: $yellow;				  
			}
		}
	}
}
