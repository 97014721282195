.scrollable-tabs
{
	&__container
	{
		@extend %collapsed-list;
		@include clearfix;
		@include horizontal-scrollbar($white-smoke, $cyan, 0.4rem, 0.6rem, $white);	
	
		margin-bottom: 1rem;
		padding-bottom: 0.125rem;
	}

	&__indicator
	{
		color: $cyan;
		cursor: pointer;
		float: right;
		font-size: larger;
		margin: 0.5rem 0 0 0.5rem;
		position: relative;
	}

	&__link
	{
		display: inline-block;
		padding: 0.2rem 1.2rem 0.4rem 1.2rem;
	}

	&__tabs
	{
		border-bottom: 0.125rem solid $cyan;
		display: table-cell;

		&__item
		{
			display: inline;
			float: none;
			
			&:nth-child(n)
			{
				padding: 0;
			}
		}
	}

	@media #{$large-up}
	{
		border-bottom: 0.125rem solid $cyan;
		margin-bottom: 1rem;
		
		&__container
		{
			margin-bottom: 0;
			padding-bottom: 0;
		}

		&__indicator
		{
			display: none;
		}

		&__link
		{
			margin-bottom: 0;
		}

		&__tabs
		{
			border-bottom: 0;

			&__item
			{
				margin: 0;
			}
		}
	}
}
