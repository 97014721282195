﻿.panel
{
	@include clearfix;
	background-color: $ghost;
	border: rem-calc(1) solid $gainsboro;
	margin-bottom: 1.5rem;
	padding: 0.75rem 1rem 1rem 1rem;

	> *:last-child
	{
		margin-bottom: 0;
	}

	&--clickable
	{
		cursor: pointer;
	}

	&__tab-content
	{
		display: none;

		&.active
		{
			display: block;
		}
	}

	&__text
	{
		font-size: 0.9rem;
		line-height: 1.4;
		margin-bottom: 1rem;

		&--large
		{
			font-size: 1rem;
		}
	}

	&__view-link
	{
		background: url('../images/icons/24x24/chevron.png') no-repeat;
		display: inline-block;
		float: right;
		font-family: $panel-font;
		font-size: 0.9rem;
		line-height: rem-calc(24);
		margin-top: 1rem;
		padding-left: 1.5rem;
	}
}
