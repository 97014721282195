
$popupColour: $oil;

.beta-message
{	
	
	float: left;
	margin: 1.84rem 0 0 0.3rem;
	position: relative;

	&__link
	{
		color: $white;
		display: block;

		&:hover
		{
			color: $white;
		}
		
		&:before
		{
			display: block;
			float: right;
			margin: 0.25rem 0 0 0.25rem;
		}
	}

	&__info-popup
	{
		background-color: $popupColour;
		box-shadow: 0 0 0.4rem rgba($black, 0.4);
		color: $white;
		font-size: 0.7rem;
		left: 5.4rem;
		opacity: 0;
		padding: 1rem;
		position: absolute;
		top: -0.7rem;
		transition: all 300ms ease-in-out;
		width: 15rem;
		
		&--open
		{
			left: 4.4rem;
			opacity: 1;
		}

		&:after
		{			
			border-color: transparent $popupColour;
			border-style: solid;
			border-width: rem-calc(8) rem-calc(13) rem-calc(8) rem-calc(0);
			content: '';
			display: block;
			left: rem-calc(-13);
			position: absolute;
			top: rem-calc(15);
			width: 0;
			z-index: 1;
		}

		&__message
		{
			margin: 0;
		}

		&__close
		{
			color: $gainsboro;
			float: right;
			font-weight: bold;

			&:hover
			{
				color: $white;
			}
		}
	}
}
