.newsletter-signup
{
	background-color: $transparent-grey;
	display: none;
	height: 100%;
	left: 0;
	padding: 0 1rem;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: z-index(5);

	&__inner
	{
		@include vertical-align;

		background-color: $white;
		margin: 0 auto;
		max-width: 20rem;
		padding: 1.5rem 2rem 1rem;
		position: relative;
		text-align: center;
		top: 50%;

		&__close
		{
			background: url('../images/icons/16x16/close-grey.png') no-repeat;
			cursor: pointer;
			display: block;
			height: 1rem;
			position: absolute;
			right: 0.5rem;
			top: 0.5rem;
			width: 1rem;
		}
		
		&__header
		{
			margin-bottom: 0.5rem;

			&__text
			{
				font-size: 1.2rem;
				font-weight: bold;

				&__logo
				{
					color: $blue;
					font-size: 1.3rem;
				}
			}
		}

		&__reasons
		{
			text-align: left;
			
			&__list
			{
				list-style: disc;
			}
		}

		&__submit
		{
			margin-bottom: 0.5rem;
			
			&__prompt-label
			{
				font-weight: bold;
			}

			&__success-label
			{
				color: $green;
				display: none;
				font-weight: bold;
			}

			&__input
			{
				margin-bottom: 0.25rem !important;
			}

			&__error
			{
				margin-bottom: 0.25rem !important;
			}

			&__disclaimer
			{
				color: $steel;
				display: block;
				font-size: 0.7rem;
				font-style: italic;
				text-align: center;
			}

			&__error-label
			{
				color: $red;
				display: none;
				margin-bottom: 0.5rem;
				text-align: center;
			}

			&__button
			{
				display: block;
				margin: 1rem auto;
				max-width: 10rem;

				&--submitting
				{
					background-image: url('../images/animations/buttonLoader.20x20.gif');
					background-position: 1rem center;
					background-repeat: no-repeat;
					padding-left: 2rem;
				}
			}
		}

		&__complete-button
		{
			display: none;
			margin: 1rem auto;
			max-width: 10rem;

			&__icon
			{
				padding-left: 0.5rem;
			}
		}
	}

	@media #{$large-up} 
	{
		&__inner
		{
			max-width: 40rem;
			text-align: left;
		}
	}	
}
