﻿h1
{
	&.larger
	{
		font-size: 2rem;
	}

	&.smaller
	{
		font-size: $h2-font-size;
	}
}

h2
{
	&.larger
	{
		font-size: $h1-font-size;
	}

	&.smaller
	{
		font-size: $h3-font-size;
	}
}

h3
{
	&.larger
	{
		font-size: $h2-font-size;
	}

	&.smaller
	{
		font-size: $h4-font-size;
	}
}

h4
{
	&.larger
	{
		font-size: $h3-font-size;
	}

	&.smaller
	{
		font-size: $h5-font-size;
	}
}

h5
{
	&.larger
	{
		font-size: $h4-font-size;
	}

	&.smaller
	{
		font-size: 0.9rem;
	}
}

h6
{
	&.larger
	{
		font-size: $h5-font-size;
	}

	&.smaller
	{
		font-size: 0.9rem;
	}
}
