﻿.link-list
{
	@extend %stacked-list;
	font-family: $link-list-font;
	font-size: 0.95rem;
	line-height: 1.2;
	margin: 0.2rem 0 1.5rem 0;

	&__item
	{
		background: url('../images/icons/16x16/chevron.png') no-repeat;
		margin-bottom: 0.75rem;
		padding-left: 1.5rem;

		&--no-bullet
		{
			background: none;
		}
	}
}
