.form
{
	margin-bottom: 1rem;

	&__entry
	{
		margin: 0 0 0.25rem 0.2rem;

		.dd-container
		{
			.dd-options
			{
				left: 0;
				top: 100%;
				width: 100%;
			}
		}

		&__label
		{
			&--inline
			{
				display: inline-block;
				margin-right: 1rem;
			}
		}

		&--center-container
		{
			text-align: center;
		}
	}

	&__list
	{
		font-size: 1rem;
		margin: 0;

		&--chunky
		{
			background-size: 2.5rem 0.5rem;
			border: rem-calc(3) solid $charcoal;
			margin: 0 0 rem-calc(10) 0;
			padding-bottom: 0.3rem;
			padding-top: 0.3rem;
		}
	}

	&__button
	{
		margin: 0;
		width: 100%;
	}

	[type="checkbox"] + label,
	[type="radio"] + label
	{
		margin-left: 0;
	}

	[type="text"]
	{
		font-size: 1rem;
		margin: 0;
	}
}
