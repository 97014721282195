body
{
	&.has-hero-skin
	{
		.site-root
		{
			border: 0;

			.site-header
			{
				z-index: z-index(1);
			}

			.site-content
			{
				background-color: transparent;

				&__top
				{
					background-color: transparent;
					border: 0;
					height: 60vh;
				}

				&__middle
				{
					background: $white;
					position: relative;
					z-index: z-index(1);
				}
			}

			.site-footer
			{
				z-index: 1;
			}
		}

		.overlay-ad
		{
			background-position: center top;
			background-repeat: no-repeat;
			background-size: contain;

			height: 100%;
			max-width: $max-site-width;
			position: fixed;
			top: 0;
			width: 100%;

			a
			{
				background: transparent 50% 0 / cover no-repeat fixed;
				display: block;
				height: 100%;
				width: 100%;
			}
		}

		.top-ad
		{
			position: relative;
			z-index: z-index(1);
		}
	}

	@media #{$medium-up}
	{
		&.has-hero-skin
		{
			.site-content
			{
				&__top
				{
					height: rem-calc(450);
				}
			}
		}
	}

	@media #{$xlarge-up}
	{
		&.has-hero-skin
		{
			.overlay-ad
			{
				background-size: none;
			}

			.site-content
			{
				&__top
				{
					height: rem-calc(555);
				}
			}
		}
	}
}
