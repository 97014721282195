$skin-narrow-gutter: rem-calc(100);
$skin-full-gutter: rem-calc(200);

$skin-large-narrow-gutter-switch: rem-calc(1300);
$skin-large-full-gutter-switch: rem-calc(1500);

body
{
	overflow-anchor: none; // scss-lint:disable PropertySpelling

	@media #{$large-up}
	{
		&.has-skin
		{
			background-color: $silver;

			.top-ad
			{
				&__sticky-wrapper
				{
					&__inner
					{
						left: 0;
						right: 0;

						&--stuck
						{
							width: auto;
						}
					}
				}
			}
		}
	}

	@media only screen and (min-width: $skin-large-narrow-gutter-switch)
	{
		&.has-skin
		{
			padding-left: $skin-narrow-gutter;
			padding-right: $skin-narrow-gutter;
		
			.site-header-desktop--revealed
			{
				padding-left: $skin-narrow-gutter;
				padding-right: $skin-narrow-gutter;
			}

			.site-header-desktop--retracted
			{
				padding-left: $skin-narrow-gutter;
				padding-right: $skin-narrow-gutter;
			}

			.top-ad
			{
				&__sticky-wrapper
				{
					&__inner
					{
						left: $skin-narrow-gutter;
						right: $skin-narrow-gutter;
					}
				}
			}
		}
	}
	
	@media only screen and (min-width: $skin-large-full-gutter-switch)
	{
		.inner-wrap
		{
			margin: 0 auto;
			max-width: $max-site-width;
		}

		&.has-skin
		{
			padding-left: $skin-full-gutter;
			padding-right: $skin-full-gutter;
	
			.site-header-desktop--revealed
			{
				padding-left: $skin-full-gutter;
				padding-right: $skin-full-gutter;
			}

			.site-header-desktop--retracted
			{
				padding-left: $skin-full-gutter;
				padding-right: $skin-full-gutter;
			}
			
			.top-ad
			{
				&__sticky-wrapper
				{
					&__inner
					{
						left: $skin-full-gutter;
						right: $skin-full-gutter;
					}
				}
			}
		}
	}
}
