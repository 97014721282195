button
{
	// reset default button styling
	background: none;
	border: 0;
	border-spacing: 0;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	margin: 0;
	padding: 0;
	text-align: inherit;
	text-decoration: inherit;
	text-indent: 0;
}
