@mixin icon-sprite($background-x, $background-y)
{	
	background-image: url('../Images/Icons/icons_1741772975795.png');
	background-position: rem-calc($background-x $background-y);
	background-repeat: no-repeat;
	background-size: rem-calc(100 7702);
}


.icon-100x100-car-pound
{
	@include icon-sprite(0, -7002);
	display: inline-block;
	height: rem-calc(100);
	width: rem-calc(100);
}

@mixin background-icon-100x100-car-pound
{
	@include icon-sprite(0, -7002);	
}

.icon-100x100-dislike-red
{
	@include icon-sprite(0, -7102);
	display: inline-block;
	height: rem-calc(100);
	width: rem-calc(100);
}

@mixin background-icon-100x100-dislike-red
{
	@include icon-sprite(0, -7102);	
}

.icon-100x100-like-green
{
	@include icon-sprite(0, -7202);
	display: inline-block;
	height: rem-calc(100);
	width: rem-calc(100);
}

@mixin background-icon-100x100-like-green
{
	@include icon-sprite(0, -7202);	
}

.icon-100x100-review
{
	@include icon-sprite(0, -7302);
	display: inline-block;
	height: rem-calc(100);
	width: rem-calc(100);
}

@mixin background-icon-100x100-review
{
	@include icon-sprite(0, -7302);	
}

.icon-100x100-search-squares
{
	@include icon-sprite(0, -7402);
	display: inline-block;
	height: rem-calc(100);
	width: rem-calc(100);
}

@mixin background-icon-100x100-search-squares
{
	@include icon-sprite(0, -7402);	
}

.icon-100x100-specs-check
{
	@include icon-sprite(0, -7502);
	display: inline-block;
	height: rem-calc(100);
	width: rem-calc(100);
}

@mixin background-icon-100x100-specs-check
{
	@include icon-sprite(0, -7502);	
}

.icon-100x100-vrm
{
	@include icon-sprite(0, -7602);
	display: inline-block;
	height: rem-calc(100);
	width: rem-calc(100);
}

@mixin background-icon-100x100-vrm
{
	@include icon-sprite(0, -7602);	
}

.icon-16x16-arrow-down
{
	@include icon-sprite(0, 0);
	display: inline-block;
	height: rem-calc(16);
	width: rem-calc(16);
}

@mixin background-icon-16x16-arrow-down
{
	@include icon-sprite(0, 0);	
}

.icon-16x16-arrow-up
{
	@include icon-sprite(0, -16);
	display: inline-block;
	height: rem-calc(16);
	width: rem-calc(16);
}

@mixin background-icon-16x16-arrow-up
{
	@include icon-sprite(0, -16);	
}

.icon-16x16-back-to-top
{
	@include icon-sprite(0, -32);
	display: inline-block;
	height: rem-calc(16);
	width: rem-calc(16);
}

@mixin background-icon-16x16-back-to-top
{
	@include icon-sprite(0, -32);	
}

.icon-16x16-blue-tick
{
	@include icon-sprite(0, -48);
	display: inline-block;
	height: rem-calc(16);
	width: rem-calc(16);
}

@mixin background-icon-16x16-blue-tick
{
	@include icon-sprite(0, -48);	
}

.icon-16x16-chevron-down
{
	@include icon-sprite(0, -64);
	display: inline-block;
	height: rem-calc(16);
	width: rem-calc(16);
}

@mixin background-icon-16x16-chevron-down
{
	@include icon-sprite(0, -64);	
}

.icon-16x16-chevron
{
	@include icon-sprite(0, -80);
	display: inline-block;
	height: rem-calc(16);
	width: rem-calc(16);
}

@mixin background-icon-16x16-chevron
{
	@include icon-sprite(0, -80);	
}

.icon-16x16-close-black
{
	@include icon-sprite(0, -96);
	display: inline-block;
	height: rem-calc(16);
	width: rem-calc(16);
}

@mixin background-icon-16x16-close-black
{
	@include icon-sprite(0, -96);	
}

.icon-16x16-close-grey
{
	@include icon-sprite(0, -112);
	display: inline-block;
	height: rem-calc(16);
	width: rem-calc(16);
}

@mixin background-icon-16x16-close-grey
{
	@include icon-sprite(0, -112);	
}

.icon-16x16-close-white
{
	@include icon-sprite(0, -128);
	display: inline-block;
	height: rem-calc(16);
	width: rem-calc(16);
}

@mixin background-icon-16x16-close-white
{
	@include icon-sprite(0, -128);	
}

.icon-16x16-info-blue
{
	@include icon-sprite(0, -144);
	display: inline-block;
	height: rem-calc(16);
	width: rem-calc(16);
}

@mixin background-icon-16x16-info-blue
{
	@include icon-sprite(0, -144);	
}

.icon-16x16-minus
{
	@include icon-sprite(0, -160);
	display: inline-block;
	height: rem-calc(16);
	width: rem-calc(16);
}

@mixin background-icon-16x16-minus
{
	@include icon-sprite(0, -160);	
}

.icon-16x16-plus-black
{
	@include icon-sprite(0, -176);
	display: inline-block;
	height: rem-calc(16);
	width: rem-calc(16);
}

@mixin background-icon-16x16-plus-black
{
	@include icon-sprite(0, -176);	
}

.icon-16x16-plus-white
{
	@include icon-sprite(0, -192);
	display: inline-block;
	height: rem-calc(16);
	width: rem-calc(16);
}

@mixin background-icon-16x16-plus-white
{
	@include icon-sprite(0, -192);	
}

.icon-16x16-remove-red-disabled
{
	@include icon-sprite(0, -208);
	display: inline-block;
	height: rem-calc(16);
	width: rem-calc(16);
}

@mixin background-icon-16x16-remove-red-disabled
{
	@include icon-sprite(0, -208);	
}

.icon-16x16-remove-red
{
	@include icon-sprite(0, -224);
	display: inline-block;
	height: rem-calc(16);
	width: rem-calc(16);
}

@mixin background-icon-16x16-remove-red
{
	@include icon-sprite(0, -224);	
}

.icon-16x16-summersky-tick
{
	@include icon-sprite(0, -240);
	display: inline-block;
	height: rem-calc(16);
	width: rem-calc(16);
}

@mixin background-icon-16x16-summersky-tick
{
	@include icon-sprite(0, -240);	
}

.icon-16x16-two-tone-arrow
{
	@include icon-sprite(0, -256);
	display: inline-block;
	height: rem-calc(16);
	width: rem-calc(16);
}

@mixin background-icon-16x16-two-tone-arrow
{
	@include icon-sprite(0, -256);	
}

.icon-16x16-warning-red
{
	@include icon-sprite(0, -272);
	display: inline-block;
	height: rem-calc(16);
	width: rem-calc(16);
}

@mixin background-icon-16x16-warning-red
{
	@include icon-sprite(0, -272);	
}

.icon-24x24-arrow-down
{
	@include icon-sprite(0, -308);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-arrow-down
{
	@include icon-sprite(0, -308);	
}

.icon-24x24-arrow-up
{
	@include icon-sprite(0, -332);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-arrow-up
{
	@include icon-sprite(0, -332);	
}

.icon-24x24-back-black
{
	@include icon-sprite(0, -356);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-back-black
{
	@include icon-sprite(0, -356);	
}

.icon-24x24-back-blue
{
	@include icon-sprite(0, -380);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-back-blue
{
	@include icon-sprite(0, -380);	
}

.icon-24x24-back-grey
{
	@include icon-sprite(0, -404);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-back-grey
{
	@include icon-sprite(0, -404);	
}

.icon-24x24-back-white
{
	@include icon-sprite(0, -428);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-back-white
{
	@include icon-sprite(0, -428);	
}

.icon-24x24-blue-circle-question-mark
{
	@include icon-sprite(0, -452);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-blue-circle-question-mark
{
	@include icon-sprite(0, -452);	
}

.icon-24x24-chevron
{
	@include icon-sprite(0, -476);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-chevron
{
	@include icon-sprite(0, -476);	
}

.icon-24x24-close-black
{
	@include icon-sprite(0, -500);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-close-black
{
	@include icon-sprite(0, -500);	
}

.icon-24x24-close-grey
{
	@include icon-sprite(0, -524);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-close-grey
{
	@include icon-sprite(0, -524);	
}

.icon-24x24-close-white
{
	@include icon-sprite(0, -548);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-close-white
{
	@include icon-sprite(0, -548);	
}

.icon-24x24-doors
{
	@include icon-sprite(0, -572);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-doors
{
	@include icon-sprite(0, -572);	
}

.icon-24x24-drivetrain
{
	@include icon-sprite(0, -596);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-drivetrain
{
	@include icon-sprite(0, -596);	
}

.icon-20x20-exit-blue
{
	@include icon-sprite(0, -288);
	display: inline-block;
	height: rem-calc(20);
	width: rem-calc(20);
}

@mixin background-icon-20x20-exit-blue
{
	@include icon-sprite(0, -288);	
}

.icon-24x24-fuel-type
{
	@include icon-sprite(0, -620);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-fuel-type
{
	@include icon-sprite(0, -620);	
}

.icon-24x24-heart-hollow-blue
{
	@include icon-sprite(0, -644);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-heart-hollow-blue
{
	@include icon-sprite(0, -644);	
}

.icon-24x24-heart-hollow-cyan
{
	@include icon-sprite(0, -668);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-heart-hollow-cyan
{
	@include icon-sprite(0, -668);	
}

.icon-24x24-heart-hollow-grey
{
	@include icon-sprite(0, -692);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-heart-hollow-grey
{
	@include icon-sprite(0, -692);	
}

.icon-24x24-heart-solid-blue
{
	@include icon-sprite(0, -716);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-heart-solid-blue
{
	@include icon-sprite(0, -716);	
}

.icon-24x24-heart-solid-cyan
{
	@include icon-sprite(0, -740);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-heart-solid-cyan
{
	@include icon-sprite(0, -740);	
}

.icon-24x24-info-blue
{
	@include icon-sprite(0, -764);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-info-blue
{
	@include icon-sprite(0, -764);	
}

.icon-24x24-info-white
{
	@include icon-sprite(0, -788);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-info-white
{
	@include icon-sprite(0, -788);	
}

.icon-24x24-leaf-black
{
	@include icon-sprite(0, -812);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-leaf-black
{
	@include icon-sprite(0, -812);	
}

.icon-24x24-magnifying-glass-blue
{
	@include icon-sprite(0, -836);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-magnifying-glass-blue
{
	@include icon-sprite(0, -836);	
}

.icon-24x24-plus-white
{
	@include icon-sprite(0, -860);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-plus-white
{
	@include icon-sprite(0, -860);	
}

.icon-24x24-pound-black
{
	@include icon-sprite(0, -884);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-pound-black
{
	@include icon-sprite(0, -884);	
}

.icon-24x24-pump-black
{
	@include icon-sprite(0, -908);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-pump-black
{
	@include icon-sprite(0, -908);	
}

.icon-24x24-spanner-black
{
	@include icon-sprite(0, -932);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-spanner-black
{
	@include icon-sprite(0, -932);	
}

.icon-24x24-tick
{
	@include icon-sprite(0, -956);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-tick
{
	@include icon-sprite(0, -956);	
}

.icon-24x24-transmission
{
	@include icon-sprite(0, -980);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-transmission
{
	@include icon-sprite(0, -980);	
}

.icon-24x24-warning-red
{
	@include icon-sprite(0, -1004);
	display: inline-block;
	height: rem-calc(24);
	width: rem-calc(24);
}

@mixin background-icon-24x24-warning-red
{
	@include icon-sprite(0, -1004);	
}

.icon-32x32-arrow-left-black
{
	@include icon-sprite(0, -1028);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-arrow-left-black
{
	@include icon-sprite(0, -1028);	
}

.icon-32x32-arrow-left-grey
{
	@include icon-sprite(0, -1060);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-arrow-left-grey
{
	@include icon-sprite(0, -1060);	
}

.icon-32x32-arrow-left-white
{
	@include icon-sprite(0, -1092);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-arrow-left-white
{
	@include icon-sprite(0, -1092);	
}

.icon-32x32-arrow-right-black
{
	@include icon-sprite(0, -1124);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-arrow-right-black
{
	@include icon-sprite(0, -1124);	
}

.icon-32x32-arrow-right-grey
{
	@include icon-sprite(0, -1156);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-arrow-right-grey
{
	@include icon-sprite(0, -1156);	
}

.icon-32x32-arrow-right-white
{
	@include icon-sprite(0, -1188);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-arrow-right-white
{
	@include icon-sprite(0, -1188);	
}

.icon-32x32-back-black
{
	@include icon-sprite(0, -1220);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-back-black
{
	@include icon-sprite(0, -1220);	
}

.icon-32x32-back-grey
{
	@include icon-sprite(0, -1252);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-back-grey
{
	@include icon-sprite(0, -1252);	
}

.icon-32x32-back-white
{
	@include icon-sprite(0, -1284);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-back-white
{
	@include icon-sprite(0, -1284);	
}

.icon-32x32-bell-alert-black
{
	@include icon-sprite(0, -1316);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-bell-alert-black
{
	@include icon-sprite(0, -1316);	
}

.icon-32x32-bell-alert-grey
{
	@include icon-sprite(0, -1348);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-bell-alert-grey
{
	@include icon-sprite(0, -1348);	
}

.icon-32x32-bell-alert-white
{
	@include icon-sprite(0, -1380);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-bell-alert-white
{
	@include icon-sprite(0, -1380);	
}

.icon-32x32-calendar
{
	@include icon-sprite(0, -1412);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-calendar
{
	@include icon-sprite(0, -1412);	
}

.icon-32x32-car-black
{
	@include icon-sprite(0, -1444);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-car-black
{
	@include icon-sprite(0, -1444);	
}

.icon-32x32-car-condition
{
	@include icon-sprite(0, -1476);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-car-condition
{
	@include icon-sprite(0, -1476);	
}

.icon-32x32-car-grey
{
	@include icon-sprite(0, -1508);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-car-grey
{
	@include icon-sprite(0, -1508);	
}

.icon-32x32-car-white
{
	@include icon-sprite(0, -1540);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-car-white
{
	@include icon-sprite(0, -1540);	
}

.icon-32x32-cars-for-sale-black
{
	@include icon-sprite(0, -1572);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-cars-for-sale-black
{
	@include icon-sprite(0, -1572);	
}

.icon-32x32-cars-for-sale-grey
{
	@include icon-sprite(0, -1604);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-cars-for-sale-grey
{
	@include icon-sprite(0, -1604);	
}

.icon-32x32-cars-for-sale-white
{
	@include icon-sprite(0, -1636);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-cars-for-sale-white
{
	@include icon-sprite(0, -1636);	
}

.icon-32x32-chevron-left-black
{
	@include icon-sprite(0, -1668);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-chevron-left-black
{
	@include icon-sprite(0, -1668);	
}

.icon-32x32-chevron-left-grey
{
	@include icon-sprite(0, -1700);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-chevron-left-grey
{
	@include icon-sprite(0, -1700);	
}

.icon-32x32-chevron-left-white
{
	@include icon-sprite(0, -1732);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-chevron-left-white
{
	@include icon-sprite(0, -1732);	
}

.icon-32x32-chevron-right-black
{
	@include icon-sprite(0, -1764);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-chevron-right-black
{
	@include icon-sprite(0, -1764);	
}

.icon-32x32-chevron-right-grey
{
	@include icon-sprite(0, -1796);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-chevron-right-grey
{
	@include icon-sprite(0, -1796);	
}

.icon-32x32-chevron-right-white
{
	@include icon-sprite(0, -1828);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-chevron-right-white
{
	@include icon-sprite(0, -1828);	
}

.icon-32x32-close-black
{
	@include icon-sprite(0, -1860);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-close-black
{
	@include icon-sprite(0, -1860);	
}

.icon-32x32-close-grey
{
	@include icon-sprite(0, -1892);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-close-grey
{
	@include icon-sprite(0, -1892);	
}

.icon-32x32-close-white
{
	@include icon-sprite(0, -1924);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-close-white
{
	@include icon-sprite(0, -1924);	
}

.icon-32x32-email-black
{
	@include icon-sprite(0, -1956);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-email-black
{
	@include icon-sprite(0, -1956);	
}

.icon-32x32-filter-black
{
	@include icon-sprite(0, -1988);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-filter-black
{
	@include icon-sprite(0, -1988);	
}

.icon-32x32-filter-grey
{
	@include icon-sprite(0, -2020);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-filter-grey
{
	@include icon-sprite(0, -2020);	
}

.icon-32x32-filter-white
{
	@include icon-sprite(0, -2052);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-filter-white
{
	@include icon-sprite(0, -2052);	
}

.icon-32x32-graph-black
{
	@include icon-sprite(0, -2084);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-graph-black
{
	@include icon-sprite(0, -2084);	
}

.icon-32x32-graph-grey
{
	@include icon-sprite(0, -2116);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-graph-grey
{
	@include icon-sprite(0, -2116);	
}

.icon-32x32-graph-white
{
	@include icon-sprite(0, -2148);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-graph-white
{
	@include icon-sprite(0, -2148);	
}

.icon-32x32-hire-black
{
	@include icon-sprite(0, -2180);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-hire-black
{
	@include icon-sprite(0, -2180);	
}

.icon-32x32-hire-grey
{
	@include icon-sprite(0, -2212);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-hire-grey
{
	@include icon-sprite(0, -2212);	
}

.icon-32x32-hire-white
{
	@include icon-sprite(0, -2244);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-hire-white
{
	@include icon-sprite(0, -2244);	
}

.icon-32x32-info-pack-black
{
	@include icon-sprite(0, -2276);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-info-pack-black
{
	@include icon-sprite(0, -2276);	
}

.icon-32x32-info-pack-grey
{
	@include icon-sprite(0, -2308);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-info-pack-grey
{
	@include icon-sprite(0, -2308);	
}

.icon-32x32-info-pack-white
{
	@include icon-sprite(0, -2340);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-info-pack-white
{
	@include icon-sprite(0, -2340);	
}

.icon-32x32-key-black
{
	@include icon-sprite(0, -2372);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-key-black
{
	@include icon-sprite(0, -2372);	
}

.icon-32x32-key-grey
{
	@include icon-sprite(0, -2404);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-key-grey
{
	@include icon-sprite(0, -2404);	
}

.icon-32x32-key-white
{
	@include icon-sprite(0, -2436);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-key-white
{
	@include icon-sprite(0, -2436);	
}

.icon-32x32-magnifying-glass-blue
{
	@include icon-sprite(0, -2468);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-magnifying-glass-blue
{
	@include icon-sprite(0, -2468);	
}

.icon-32x32-milometer
{
	@include icon-sprite(0, -2500);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-milometer
{
	@include icon-sprite(0, -2500);	
}

.icon-32x32-newspaper-black
{
	@include icon-sprite(0, -2532);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-newspaper-black
{
	@include icon-sprite(0, -2532);	
}

.icon-32x32-newspaper-grey
{
	@include icon-sprite(0, -2564);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-newspaper-grey
{
	@include icon-sprite(0, -2564);	
}

.icon-32x32-newspaper-white
{
	@include icon-sprite(0, -2596);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-newspaper-white
{
	@include icon-sprite(0, -2596);	
}

.icon-32x32-petrol-pump
{
	@include icon-sprite(0, -2628);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-petrol-pump
{
	@include icon-sprite(0, -2628);	
}

.icon-32x32-plug
{
	@include icon-sprite(0, -2660);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-plug
{
	@include icon-sprite(0, -2660);	
}

.icon-32x32-price-tag-black
{
	@include icon-sprite(0, -2692);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-price-tag-black
{
	@include icon-sprite(0, -2692);	
}

.icon-32x32-price-tag-grey
{
	@include icon-sprite(0, -2724);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-price-tag-grey
{
	@include icon-sprite(0, -2724);	
}

.icon-32x32-price-tag-white
{
	@include icon-sprite(0, -2756);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-price-tag-white
{
	@include icon-sprite(0, -2756);	
}

.icon-32x32-rating-black
{
	@include icon-sprite(0, -2788);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-rating-black
{
	@include icon-sprite(0, -2788);	
}

.icon-32x32-rating-grey
{
	@include icon-sprite(0, -2820);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-rating-grey
{
	@include icon-sprite(0, -2820);	
}

.icon-32x32-rating-white
{
	@include icon-sprite(0, -2852);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-rating-white
{
	@include icon-sprite(0, -2852);	
}

.icon-32x32-reviews-black
{
	@include icon-sprite(0, -2884);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-reviews-black
{
	@include icon-sprite(0, -2884);	
}

.icon-32x32-reviews-grey
{
	@include icon-sprite(0, -2916);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-reviews-grey
{
	@include icon-sprite(0, -2916);	
}

.icon-32x32-reviews-white
{
	@include icon-sprite(0, -2948);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-reviews-white
{
	@include icon-sprite(0, -2948);	
}

.icon-32x32-road-black
{
	@include icon-sprite(0, -2980);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-road-black
{
	@include icon-sprite(0, -2980);	
}

.icon-32x32-road-grey
{
	@include icon-sprite(0, -3012);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-road-grey
{
	@include icon-sprite(0, -3012);	
}

.icon-32x32-road-white
{
	@include icon-sprite(0, -3044);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-road-white
{
	@include icon-sprite(0, -3044);	
}

.icon-32x32-shield-black
{
	@include icon-sprite(0, -3076);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-shield-black
{
	@include icon-sprite(0, -3076);	
}

.icon-32x32-shield-grey
{
	@include icon-sprite(0, -3108);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-shield-grey
{
	@include icon-sprite(0, -3108);	
}

.icon-32x32-shield-white
{
	@include icon-sprite(0, -3140);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-shield-white
{
	@include icon-sprite(0, -3140);	
}

.icon-32x32-sidebar-black
{
	@include icon-sprite(0, -3172);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-sidebar-black
{
	@include icon-sprite(0, -3172);	
}

.icon-32x32-sidebar-grey
{
	@include icon-sprite(0, -3204);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-sidebar-grey
{
	@include icon-sprite(0, -3204);	
}

.icon-32x32-sidebar-white
{
	@include icon-sprite(0, -3236);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-sidebar-white
{
	@include icon-sprite(0, -3236);	
}

.icon-32x32-star
{
	@include icon-sprite(0, -3268);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-star
{
	@include icon-sprite(0, -3268);	
}

.icon-32x32-warning-red
{
	@include icon-sprite(0, -3300);
	display: inline-block;
	height: rem-calc(32);
	width: rem-calc(32);
}

@mixin background-icon-32x32-warning-red
{
	@include icon-sprite(0, -3300);	
}

.icon-35x35-car-valuation-blue
{
	@include icon-sprite(0, -3332);
	display: inline-block;
	height: rem-calc(35);
	width: rem-calc(35);
}

@mixin background-icon-35x35-car-valuation-blue
{
	@include icon-sprite(0, -3332);	
}

.icon-35x35-costs
{
	@include icon-sprite(0, -3367);
	display: inline-block;
	height: rem-calc(35);
	width: rem-calc(35);
}

@mixin background-icon-35x35-costs
{
	@include icon-sprite(0, -3367);	
}

.icon-35x35-dimensions
{
	@include icon-sprite(0, -3402);
	display: inline-block;
	height: rem-calc(35);
	width: rem-calc(35);
}

@mixin background-icon-35x35-dimensions
{
	@include icon-sprite(0, -3402);	
}

.icon-35x35-engine
{
	@include icon-sprite(0, -3437);
	display: inline-block;
	height: rem-calc(35);
	width: rem-calc(35);
}

@mixin background-icon-35x35-engine
{
	@include icon-sprite(0, -3437);	
}

.icon-35x35-finance-green
{
	@include icon-sprite(0, -3472);
	display: inline-block;
	height: rem-calc(35);
	width: rem-calc(35);
}

@mixin background-icon-35x35-finance-green
{
	@include icon-sprite(0, -3472);	
}

.icon-35x35-find-cars-for-sale-red
{
	@include icon-sprite(0, -3507);
	display: inline-block;
	height: rem-calc(35);
	width: rem-calc(35);
}

@mixin background-icon-35x35-find-cars-for-sale-red
{
	@include icon-sprite(0, -3507);	
}

.icon-35x35-luggage
{
	@include icon-sprite(0, -3542);
	display: inline-block;
	height: rem-calc(35);
	width: rem-calc(35);
}

@mixin background-icon-35x35-luggage
{
	@include icon-sprite(0, -3542);	
}

.icon-35x35-magnifying-glass-blue
{
	@include icon-sprite(0, -3577);
	display: inline-block;
	height: rem-calc(35);
	width: rem-calc(35);
}

@mixin background-icon-35x35-magnifying-glass-blue
{
	@include icon-sprite(0, -3577);	
}

.icon-35x35-miles-per-pound
{
	@include icon-sprite(0, -3612);
	display: inline-block;
	height: rem-calc(35);
	width: rem-calc(35);
}

@mixin background-icon-35x35-miles-per-pound
{
	@include icon-sprite(0, -3612);	
}

.icon-35x35-money-pound-blue
{
	@include icon-sprite(0, -3647);
	display: inline-block;
	height: rem-calc(35);
	width: rem-calc(35);
}

@mixin background-icon-35x35-money-pound-blue
{
	@include icon-sprite(0, -3647);	
}

.icon-35x35-overview
{
	@include icon-sprite(0, -3682);
	display: inline-block;
	height: rem-calc(35);
	width: rem-calc(35);
}

@mixin background-icon-35x35-overview
{
	@include icon-sprite(0, -3682);	
}

.icon-35x35-performance
{
	@include icon-sprite(0, -3717);
	display: inline-block;
	height: rem-calc(35);
	width: rem-calc(35);
}

@mixin background-icon-35x35-performance
{
	@include icon-sprite(0, -3717);	
}

.icon-35x35-read-reviews-yellow
{
	@include icon-sprite(0, -3752);
	display: inline-block;
	height: rem-calc(35);
	width: rem-calc(35);
}

@mixin background-icon-35x35-read-reviews-yellow
{
	@include icon-sprite(0, -3752);	
}

.icon-35x35-remove-red-disabled
{
	@include icon-sprite(0, -3787);
	display: inline-block;
	height: rem-calc(35);
	width: rem-calc(35);
}

@mixin background-icon-35x35-remove-red-disabled
{
	@include icon-sprite(0, -3787);	
}

.icon-35x35-remove-red
{
	@include icon-sprite(0, -3822);
	display: inline-block;
	height: rem-calc(35);
	width: rem-calc(35);
}

@mixin background-icon-35x35-remove-red
{
	@include icon-sprite(0, -3822);	
}

.icon-35x35-safety
{
	@include icon-sprite(0, -3857);
	display: inline-block;
	height: rem-calc(35);
	width: rem-calc(35);
}

@mixin background-icon-35x35-safety
{
	@include icon-sprite(0, -3857);	
}

.icon-35x35-specs-check
{
	@include icon-sprite(0, -3892);
	display: inline-block;
	height: rem-calc(35);
	width: rem-calc(35);
}

@mixin background-icon-35x35-specs-check
{
	@include icon-sprite(0, -3892);	
}

.icon-35x35-thought
{
	@include icon-sprite(0, -3927);
	display: inline-block;
	height: rem-calc(35);
	width: rem-calc(35);
}

@mixin background-icon-35x35-thought
{
	@include icon-sprite(0, -3927);	
}

.icon-50x50-car-valuation-blue
{
	@include icon-sprite(0, -3962);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-car-valuation-blue
{
	@include icon-sprite(0, -3962);	
}

.icon-50x50-car-valuation-grey
{
	@include icon-sprite(0, -4012);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-car-valuation-grey
{
	@include icon-sprite(0, -4012);	
}

.icon-50x50-car-white
{
	@include icon-sprite(0, -4062);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-car-white
{
	@include icon-sprite(0, -4062);	
}

.icon-50x50-dislike-red
{
	@include icon-sprite(0, -4112);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-dislike-red
{
	@include icon-sprite(0, -4112);	
}

.icon-50x50-driver-white
{
	@include icon-sprite(0, -4162);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-driver-white
{
	@include icon-sprite(0, -4162);	
}

.icon-50x50-finance-green
{
	@include icon-sprite(0, -4212);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-finance-green
{
	@include icon-sprite(0, -4212);	
}

.icon-50x50-finance-grey
{
	@include icon-sprite(0, -4262);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-finance-grey
{
	@include icon-sprite(0, -4262);	
}

.icon-50x50-find-cars-for-sale-blue
{
	@include icon-sprite(0, -4312);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-find-cars-for-sale-blue
{
	@include icon-sprite(0, -4312);	
}

.icon-50x50-find-cars-for-sale-grey
{
	@include icon-sprite(0, -4362);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-find-cars-for-sale-grey
{
	@include icon-sprite(0, -4362);	
}

.icon-50x50-find-cars-for-sale-red
{
	@include icon-sprite(0, -4412);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-find-cars-for-sale-red
{
	@include icon-sprite(0, -4412);	
}

.icon-50x50-find-vans-for-sale-red
{
	@include icon-sprite(0, -4462);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-find-vans-for-sale-red
{
	@include icon-sprite(0, -4462);	
}

.icon-50x50-for-sale-hub-car-blue
{
	@include icon-sprite(0, -4512);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-for-sale-hub-car-blue
{
	@include icon-sprite(0, -4512);	
}

.icon-50x50-instagram
{
	@include icon-sprite(0, -5162);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-instagram
{
	@include icon-sprite(0, -5162);	
}

.icon-50x50-like-green
{
	@include icon-sprite(0, -4562);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-like-green
{
	@include icon-sprite(0, -4562);	
}

.icon-50x50-magnifying-glass-white
{
	@include icon-sprite(0, -4612);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-magnifying-glass-white
{
	@include icon-sprite(0, -4612);	
}

.icon-50x50-people
{
	@include icon-sprite(0, -4662);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-people
{
	@include icon-sprite(0, -4662);	
}

.icon-50x50-read-reviews-blue
{
	@include icon-sprite(0, -4712);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-read-reviews-blue
{
	@include icon-sprite(0, -4712);	
}

.icon-50x50-read-reviews-grey
{
	@include icon-sprite(0, -4762);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-read-reviews-grey
{
	@include icon-sprite(0, -4762);	
}

.icon-50x50-read-reviews-yellow
{
	@include icon-sprite(0, -4812);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-read-reviews-yellow
{
	@include icon-sprite(0, -4812);	
}

.icon-50x50-review
{
	@include icon-sprite(0, -4862);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-review
{
	@include icon-sprite(0, -4862);	
}

.icon-50x50-specs-check
{
	@include icon-sprite(0, -4912);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-specs-check
{
	@include icon-sprite(0, -4912);	
}

.icon-50x50-thought
{
	@include icon-sprite(0, -4962);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-thought
{
	@include icon-sprite(0, -4962);	
}

.icon-50x50-twitter
{
	@include icon-sprite(0, -5212);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-twitter
{
	@include icon-sprite(0, -5212);	
}

.icon-50x50-van-articles-blue
{
	@include icon-sprite(0, -5012);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-van-articles-blue
{
	@include icon-sprite(0, -5012);	
}

.icon-50x50-van-valuation-green
{
	@include icon-sprite(0, -5062);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-van-valuation-green
{
	@include icon-sprite(0, -5062);	
}

.icon-50x50-van-white
{
	@include icon-sprite(0, -5112);
	display: inline-block;
	height: rem-calc(50);
	width: rem-calc(50);
}

@mixin background-icon-50x50-van-white
{
	@include icon-sprite(0, -5112);	
}

.icon-60x60-car-leasing-tool
{
	@include icon-sprite(0, -5262);
	display: inline-block;
	height: rem-calc(60);
	width: rem-calc(60);
}

@mixin background-icon-60x60-car-leasing-tool
{
	@include icon-sprite(0, -5262);	
}

.icon-60x60-car-tax-tool
{
	@include icon-sprite(0, -5322);
	display: inline-block;
	height: rem-calc(60);
	width: rem-calc(60);
}

@mixin background-icon-60x60-car-tax-tool
{
	@include icon-sprite(0, -5322);	
}

.icon-60x60-finance-tool
{
	@include icon-sprite(0, -5382);
	display: inline-block;
	height: rem-calc(60);
	width: rem-calc(60);
}

@mixin background-icon-60x60-finance-tool
{
	@include icon-sprite(0, -5382);	
}

.icon-60x60-insurance-groups-tool
{
	@include icon-sprite(0, -5442);
	display: inline-block;
	height: rem-calc(60);
	width: rem-calc(60);
}

@mixin background-icon-60x60-insurance-groups-tool
{
	@include icon-sprite(0, -5442);	
}

.icon-75x75-car-valuation-blue
{
	@include icon-sprite(0, -5502);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-car-valuation-blue
{
	@include icon-sprite(0, -5502);	
}

.icon-75x75-clipboard
{
	@include icon-sprite(0, -5577);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-clipboard
{
	@include icon-sprite(0, -5577);	
}

.icon-75x75-finance-green
{
	@include icon-sprite(0, -5652);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-finance-green
{
	@include icon-sprite(0, -5652);	
}

.icon-75x75-find-cars-for-sale-blue
{
	@include icon-sprite(0, -5727);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-find-cars-for-sale-blue
{
	@include icon-sprite(0, -5727);	
}

.icon-75x75-find-cars-for-sale-red
{
	@include icon-sprite(0, -5802);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-find-cars-for-sale-red
{
	@include icon-sprite(0, -5802);	
}

.icon-75x75-find-vans-for-sale-red
{
	@include icon-sprite(0, -5877);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-find-vans-for-sale-red
{
	@include icon-sprite(0, -5877);	
}

.icon-75x75-lifestyle-all-seasons
{
	@include icon-sprite(0, -5952);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-lifestyle-all-seasons
{
	@include icon-sprite(0, -5952);	
}

.icon-75x75-lifestyle-big-boot
{
	@include icon-sprite(0, -6027);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-lifestyle-big-boot
{
	@include icon-sprite(0, -6027);	
}

.icon-75x75-lifestyle-city-driving
{
	@include icon-sprite(0, -6102);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-lifestyle-city-driving
{
	@include icon-sprite(0, -6102);	
}

.icon-75x75-lifestyle-company-car
{
	@include icon-sprite(0, -6177);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-lifestyle-company-car
{
	@include icon-sprite(0, -6177);	
}

.icon-75x75-lifestyle-easy-access
{
	@include icon-sprite(0, -6252);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-lifestyle-easy-access
{
	@include icon-sprite(0, -6252);	
}

.icon-75x75-lifestyle-long-journey
{
	@include icon-sprite(0, -6327);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-lifestyle-long-journey
{
	@include icon-sprite(0, -6327);	
}

.icon-75x75-lifestyle-new-drivers
{
	@include icon-sprite(0, -6402);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-lifestyle-new-drivers
{
	@include icon-sprite(0, -6402);	
}

.icon-75x75-lifestyle-statement
{
	@include icon-sprite(0, -6477);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-lifestyle-statement
{
	@include icon-sprite(0, -6477);	
}

.icon-75x75-people
{
	@include icon-sprite(0, -6552);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-people
{
	@include icon-sprite(0, -6552);	
}

.icon-75x75-read-reviews-blue
{
	@include icon-sprite(0, -6627);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-read-reviews-blue
{
	@include icon-sprite(0, -6627);	
}

.icon-75x75-read-reviews-yellow
{
	@include icon-sprite(0, -6702);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-read-reviews-yellow
{
	@include icon-sprite(0, -6702);	
}

.icon-75x75-specs
{
	@include icon-sprite(0, -6777);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-specs
{
	@include icon-sprite(0, -6777);	
}

.icon-75x75-van-articles-blue
{
	@include icon-sprite(0, -6852);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-van-articles-blue
{
	@include icon-sprite(0, -6852);	
}

.icon-75x75-van-valuation-green
{
	@include icon-sprite(0, -6927);
	display: inline-block;
	height: rem-calc(75);
	width: rem-calc(75);
}

@mixin background-icon-75x75-van-valuation-green
{
	@include icon-sprite(0, -6927);	
}
