.vrm-lookup
{
	&__lookup-error
	{
		background-color: $red;
		color: $white;
		display: none;
		margin-bottom: 1rem;
		padding: 0.5rem;
		text-align: center;
	}

	&__button__spinner
	{
		animation: spin 1s infinite linear;
		border: 0.25rem solid $white;
		border-radius: 50%;
		border-top-color: $blue;
		float: right;
		height: 30px;
		margin-right: 1rem;
		width: 30px;
	}

	&__recaptcha-panel
	{
		display: none;

		&__recaptcha
		{
			> div
			{
				margin: 0 auto;
			}
		}
	}

	&__inline-layout
	{
		display: flex;
		flex-wrap: wrap;

		.vrm-lookup
		{
			&__input
			{
				flex: 1 15rem;
				font-size: 1.3rem; 
				margin-right: 1rem;

				@media #{$medium-only}
				{
					font-size: 2.3rem;
				}

				@media #{$large-up}
				{
					font-size: 2.8rem;
				}
			}

			&__recaptcha-panel
			{
				margin: auto;
				padding-bottom: 0.25rem;
			}

			&__button
			{
				height: 3rem;
				max-width: 100%;
				top: 0.25rem;
				width: 7rem;
			}
		}
	}
}
