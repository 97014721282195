.radio-option
{
	display: inline-block;
	padding-bottom: rem-calc(8);

	.radio-option
	{
		&__label
		{
			&__circle
			{
				background-color: $white;
				border: rem-calc(1) solid $black;
				border-radius: 99rem;
				display: inline-block;
				height: 1rem;
				padding: rem-calc(3);
				vertical-align: text-bottom;
				width: 1rem;
			}
		}

		&__input
		{
			display: none;

			&:checked + .radio-option__label
			{
				.radio-option__label__circle
				{
					&:before
					{
						background-color: $black;
						border-radius: 99rem;
						content: ' ';
						display: block;
						height: 0.5rem;
						position: relative;
						width: 0.5rem;
					}
				}
			}
		}
	}
	
	@media #{$large-up}
	{
		padding-bottom: 0;
	}
}
