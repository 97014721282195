﻿@media #{$large-up}
{
	.off-canvas-wrap.move-left
	{
		.inner-wrap
		{
			transform: none !important;
		}

		.exit-off-canvas
		{
			display: none !important;
		}
	}

	.off-canvas-menu-mobile
	{
		display: none !important;
	}
}

@media #{$xlarge-only}
{
	.off-canvas-wrap.move-right
	{
		[class*="--stuck"]
		{
			position: static !important;
		}

		.top-ad__sticky-wrapper__inner--stuck
		{
			background-color: none !important;
			border-bottom: 0 !important;
			box-sizing: inherit !important;
			padding: 0 !important;
		}
	}
}

@media #{$xlarge-up}
{
	.off-canvas-wrap.move-right
	{
		.inner-wrap
		{
			transform: none !important;
		}

		.exit-off-canvas
		{
			display: none !important;
		}
	}

	.off-canvas-menu-mobile
	{
		display: none !important;
	}

	.site-search-tablet
	{
		display: none !important;
	}
}
