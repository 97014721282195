﻿.icon
{
	$tiny-icon-size: rem-calc(12);
	$small-icon-size: rem-calc(16);
	$medium-icon-size: rem-calc(24);
	$large-icon-size: rem-calc(32);

	background-position: left center;
	background-repeat: no-repeat;
	display: inline-block;
	font-size: $small-icon-size;

	&--tiny
	{
		font-size: $tiny-icon-size;
	}

	&--medium
	{
		font-size: $medium-icon-size;
	}

	&--large
	{
		font-size: $large-icon-size;
	}

	&--black
	{
		color: $black;
	}

	&--green
	{
		color: $green;
	}

	&-gap
	{
		&-left
		{
			margin-left: 0.5rem;
		}

		&-right
		{
			margin-right: 0.5rem;
		}

		&-top
		{
			margin-top: 0.5rem;
		}

		&-bottom
		{
			margin-bottom: 0.5rem;
		}
	}
}
