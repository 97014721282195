.quotation
{
	font-family: $quotation-font;
	font-size: 1rem;
	margin-left: 0.5rem;
	margin-right: 1rem;
	position: relative;
	text-indent: 1rem;

	&:before,
	&:after
	{
		color: $base;
		font-family: $body-font;
		font-size: 3rem;
		position: absolute;
	}

	&:before
	{
		content: '\201C';
		left: -1.5rem;
		top: -1rem;
	}

	&:after
	{
		content: '\201D';
		margin-left: -0.5rem;
		margin-top: -0.65rem;
	}

	&--large
	{
		font-size: 1.2rem;
		text-indent: 2rem;

		&:before,
		&:after
		{
			font-size: 4rem;
		}

		&:before
		{
			left: -2rem;
			top: -1.5rem;
		}

		&:after
		{
			margin-left: -1.5rem;
			margin-top: -0.75rem;
		}
	}

	&--open-only
	{
		&:after
		{
			content: '';
		}

		*:not(:first-child)
		{
			text-indent: 0;
		}
	}
}
