﻿
$site-header-badge-size: 30;

.site-header-mobile
{
	background-image: linear-gradient(lighten($blue, 5%), $blue);
	box-sizing: content-box;
	height: $mobile-header-height;
	width: 100%;
	z-index: z-index(4);

	&--retracted
	{
		left: 0;
		position: fixed;
		top: 0;
		transform: translateY(-100%);

		&.site-header-mobile--revealed
		{
			transform: none;
		}

	}

	&--transition
	{
		transition: transform 0.7s;
	}


	.left-small,
	.right-small,
	.tab-bar-section.middle
	{
		box-sizing: border-box;
	}	

	.tab-bar-section.middle
	{
		padding: 0;
		position: initial;
	}

	.site-header-mobile
	{
		&__logo
		{
			margin-bottom: 0.15rem;
		}

		&__text-title
		{
			display: inline-block;
			text-indent: $negative-indent;
		}
	
		&__icon
		{
			background: $dark-blue;
			color: $white;
			display: block;
			font-size: 2rem;
			height: $mobile-header-height;
			line-height: $mobile-header-height;
			text-align: center;
			width: $mobile-header-height;

			&:hover
			{
				background-color: $dark-blue;
				color: $white;
			}

			&--menu
			{
				.site-header-mobile__icon__text
				{
					display: none;
					text-indent: -100rem;
				}
			}

			&--saved-cars
			{
				padding-top: rem-calc(($mobile-header-height-value - $site-header-badge-size)/2);
			}
		}

		&__badge
		{
			background-color: $blue;
			border-radius: rem-calc($site-header-badge-size/2);
			color: $white;
			display: none;
			font-family: $heading-font;
			font-size: 1rem;
			font-weight: bold;
			line-height: rem-calc($site-header-badge-size);
			margin: 0 auto;
			text-align: center;
			width: rem-calc($site-header-badge-size);

			&__container
			{
				display: inline;
				height: $mobile-header-height;
				padding: rem-calc(($mobile-header-height-value - $site-header-badge-size)/2) 0;
			}

			&--added,
			&--removed
			{
				animation: fadeIn 800ms alternate infinite;
				display: block;
			}

			&--added
			{
				background-color: $green;
			}

			&--removed
			{
				background-color: $red;
			}
		}
	}

	@media #{$medium-only}
	{
		.left-small,
		.right-small
		{
			width: auto;
		}

		.site-header-mobile
		{
			&__icon
			{
				&--menu
				{
					font-family: $menu-font;
					font-size: 1.4rem;
					line-height: 1;
					padding: 0.6rem 0.75rem 0;
					width: auto;

					.site-header-mobile__icon__text
					{
						display: inline;
						text-indent: 0;
					}
				}

				&--saved-cars
				{
					padding-top: rem-calc(($mobile-header-height-value - $site-header-badge-size)/2);
				}
			}
		}
	}

	@media #{$large-up}
	{
		display: none;
	}
}
