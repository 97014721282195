﻿.star-rating
{
	$rating-small-height: 16;
	$rating-small-width: $rating-small-height * 5;
	$rating-medium-height: 20;
	$rating-medium-width: $rating-medium-height * 5;
	$rating-large-height: 30;
	$rating-large-width: $rating-large-height * 5;

	$star-rating-small-image: '../images/rating/star.sprite.16x16.png';
	$star-rating-medium-image: '../images/rating/star.sprite.20x20.png';
	$star-rating-large-image: '../images/rating/star.sprite.30x30.png';

	$star-rating-small-image-blue: '../images/rating/star.sprite.16x16.blue.png';
	$star-rating-medium-image-blue: '../images/rating/star.sprite.20x20.blue.png';
	$star-rating-large-image-blue: '../images/rating/star.sprite.30x30.blue.png';

	&__no-rating
	{
		display: inline-block;
		font-size: 0.9rem;
	}

	&__value-container
	{
		display: inline-block;
	}

	&__text
	{
		color: $black;
		float: right;
		font-family: $info-box-font;
		font-size: 1.2rem;
		font-weight: normal;
		line-height: 1;
		margin-left: 0.5rem;

		&__decimal-value
		{
			font-size: 0.9rem;
		}
	}

	&__stars
	{
		background: url(#{$star-rating-medium-image}) repeat-x;
		display: inline-block;
		height: rem-calc($rating-medium-height);
		width: rem-calc($rating-medium-width);

		&__value
		{
			background: url(#{$star-rating-medium-image}) repeat-x 0 rem-calc(-$rating-medium-height);
			display: block;
			height: rem-calc($rating-medium-height);
			text-align: left;
			text-indent: $negative-indent;
		}

		&--input
		{
			cursor: pointer;
		}
	}	

	&--small
	{
		.star-rating__stars
		{
			background-image: url(#{$star-rating-small-image});
			height: rem-calc($rating-small-height);
			width: rem-calc($rating-small-width);

			&__value
			{
				background-image: url(#{$star-rating-small-image});
				background-position: 0 rem-calc(-$rating-small-height);
				height: rem-calc($rating-small-height);
			}
		}
	}

	&--large
	{
		.star-rating__stars
		{
			background-image: url(#{$star-rating-large-image});
			height: rem-calc($rating-large-height);
			width: rem-calc($rating-large-width);

			&__value
			{
				background-image: url(#{$star-rating-large-image});
				background-position: 0 rem-calc(-$rating-large-height);
				height: rem-calc($rating-large-height);
			}
		}
	}

	&--blue
	{
		.star-rating__stars
		{
			background-image: url(#{$star-rating-medium-image-blue});

			&__value
			{
				background-image: url(#{$star-rating-medium-image-blue});
			}
		}

		&.star-rating--small
		{
			.star-rating__stars
			{
				background-image: url(#{$star-rating-small-image-blue});

				&__value
				{
					background-image: url(#{$star-rating-small-image-blue});
				}
			}
		}

		&.star-rating--large
		{
			.star-rating__stars
			{
				background-image: url(#{$star-rating-large-image-blue});

				&__value
				{
					background-image: url(#{$star-rating-large-image-blue});
				}
			}
		}
	}
}
