.back-to-top
{
	background: $white;
	border: rem-calc(2) solid $cyan;
	border-radius: rem-calc(5 0 0 5);
	border-right: 0;
	color: $blue;
	display: none;
	float: right;
	font-family: $back-to-top-font;
	font-size: 0.8rem;
	margin-right: 0;
	padding: 0.2rem 0 0.2rem 0.5rem;
	z-index: 1000;

	&__wrapper
	{
		bottom: rem-calc(75);
		position: fixed;
		right: 0;
		width: 100%;
		z-index: 1000;

		&__inner
		{
			margin: 0 auto;
			max-width: $max-site-width;
		}
	}

	&__icon
	{
		margin-right: 0;
		vertical-align: middle;
	}

	&__label
	{
		margin-right: 0.5rem;
	}

	@media #{$large-up}
	{
		border-bottom: 0;
		border-radius: rem-calc(5 5 0 0);
		border-right: rem-calc(2) solid $cyan;
		font-size: 0.9rem;
		margin-right: 1.5rem;
		padding-right: 0.5rem;
		
		&__wrapper
		{
			bottom: 0;
			left: 0;

			&--raised
			{
				bottom: $raised-footer-height - rem-calc(1);
			}
		}

		&__icon
		{
			margin-right: 0.5rem;
		}

		&__label
		{
			margin-right: 0;
		}
	}
}
