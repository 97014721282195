﻿.primary-nav-mobile
{
	.primary-nav-mobile
	{
		&__menu
		{
			&__link
			{
				box-sizing: content-box;
				font-family: $menu-font;
				font-size: 1rem;
				text-transform: uppercase;
				transition: background-color 200ms;

				&:hover
				{
					color: $white;
				}

				&--has-sub-menu:after
				{
					@include triangle('down', rem-calc(6), rem-calc(6), $blue);
					float: right;
					margin: rem-calc(8 0 0 8);
				}

				&--has-sub-menu:hover:after
				{
					border-top-color: $white;
				}
			}
		}

		&__sub-menu
		{
			background-color: $background-blue;
			display: none;

			&__link
			{
				box-sizing: content-box;
				color: $blue;
				display: block;
				font-family: $menu-font;
				font-size: 1rem;
				height: $mobile-sub-menu-item-height;
				line-height: $mobile-sub-menu-item-height;
				padding: 0 rem-calc(11);
				text-transform: none;

				&:hover
				{
					color: $white;
				}
			}
		}
	}
}
