@keyframes fadeIn
{
	from
	{
		opacity: 0;
	}

	to
	{
		opacity: 1;
	}
}

@keyframes spin
{
	0%
	{
		transform: rotate(0deg);
	}

	100%
	{
		transform: rotate(360deg);
	}
}

@keyframes pop
{
	50%
	{
		transform: scale(1.1);
	}
}
