﻿.body-styles
{
	&__link
	{
		background-position: center top;
		background-repeat: no-repeat;
		color: $steel;
		display: block;
		font-family: $body-style-font;
		font-size: 1rem;
		letter-spacing: 0.02rem;
		min-width: rem-calc(100);
		text-align: center;
		
		&__icon
		{
			@include inactive-body-styles;
			
			display: block;
			margin: 0 auto;
		}
		
		&:hover,
		&:focus
		{
			color: $black;

			.body-styles__link__icon
			{
				@include active-body-styles;
			}
		}

		&--disabled
		{
			color: $iron;
			cursor: default;

			&:hover,
			&:focus
			{
				background-image: inherit;
				color: $iron;
				cursor: default;

				.body-styles__link__icon
				{
					@include disabled-body-styles;
				}
			}

			.body-styles__link__icon
			{
				@include disabled-body-styles;
			}
		}

		&--selected
		{
			color: $white;

			&:hover,
			&:focus
			{
				background-image: inherit;
				color: $white;

				.body-styles__link__icon
				{
					@include selected-body-styles;
				}
			}

			.body-styles__link__icon
			{
				@include selected-body-styles;
			}
		}		
	}
}
