.cms-content 
{
	p
	{
		margin-bottom: 1rem;
	}

	.left 
	{
		margin: 1.5rem 1.5rem 1.5rem 0;
	}

	.right
	{
		margin: 1.5rem 0 1.5rem 1.5rem;
	}

	.full-width-image 
	{
		margin: 1.5rem 0;
		width: 100%;
	}

	.block-image
	{
		margin-bottom: 0.5rem;
	}
}
